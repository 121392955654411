import React from "react";
import { Row, Col } from "react-bootstrap";

const Fieldset = ({ title, children }) => (
  <fieldset className="border p-2 mb-4">
    <legend className="w-auto">{title}</legend>
    <Row>{children}</Row>
  </fieldset>
);

export default Fieldset;
