import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Breadcrumb,
} from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import Fieldset from "./Fieldset";
import SearchableDropdown from "./SearchableDropdown";
import LeadEvents from "./LeadEvents";
import { useLocation } from "react-router-dom";
import {
  fetchOptions,
  fetchInitialData,
  fetchEditData,
  formFields,
  // tokenConfig,
  toastAttr,
  allocateMandap,
  showToastForUnavailableDates,
  checkAvailability,
  detectChanges,
} from "./utils";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../config";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "../../Helper/Breadcrumbs";

const OfflineLeadsForm = () => {
  const location = useLocation();
  const editMode = location?.state?.editMode || false;
  const leadId = location?.state?.id || null;
  const history = useHistory();
  const tokenConfig = {
    headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
  };
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [kaeLoading, setKaeLoading] = useState(false);
  const [mandapLoading, setMandapLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [kaeOptions, setKaeOptions] = useState([]);
  const [mandapOptions, setMandapOptions] = useState([]);
  const [lead, setLead] = useState({
    kae: {},
    mandap: {},
    customerName: "",
    contactNumber: "",
    email: "",
    remarks: "",
    address: "",
    pincode: "",
    leadEvents: [],
  });
  const [originalData, setOriginalData] = useState({
    kae: {},
    mandap: {},
    customerName: "",
    contactNumber: "",
    email: "",
    remarks: "",
    address: "",
    pincode: "",
    leadEvents: [],
  });

  useEffect(() => {
    const loadInitialData = async () => {
      const data = await fetchInitialData(tokenConfig);
      setKaeOptions(data.kaeOptions);
      setMandapOptions(data.mandapOptions);
      setLoading(false);
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    if (!editMode || !leadId) {
      return;
    }
    // if (editMode && leadId) {
    //   const loadEditData = async () => {
    //     const data = await fetchEditData(leadId);
    //     if (data) {
    //       setLead(data);
    //       setOriginalData(data);
    //     }
    //     setLoading(false);
    //   };
    //   loadEditData();
    // }
    const loadEditData = async () => {
      try {
        const data = await fetchEditData(leadId, tokenConfig);
        if (data) {
          setLead(data);
          setOriginalData(data);
        }
      } catch (error) {
        console.error("Error fetching edit data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadEditData();
  }, [editMode, leadId]);
  const handleInputChange = (field, value) => {
    if (field === "leadEvents") {
      setLead((prevLead) => {
        const currentEvents = prevLead[field] || [];
        const updatedEvents = value.reduce((acc, newEvent) => {
          acc = acc.filter(
            (event) => event.event_date_from !== newEvent.event_date_from
          );

          return [...acc, newEvent];
        }, currentEvents);

        const finalEvents = updatedEvents.filter((event) =>
          value.some(
            (newEvent) => newEvent.event_date_from === event.event_date_from
          )
        );

        return {
          ...prevLead,
          [field]: finalEvents,
        };
      });
    } else {
      setLead((prevLead) => ({
        ...prevLead,
        [field]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    formFields.forEach((field) => {
      if (field.required && !lead[field.name]) {
        newErrors[field.name] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    setSaveLoading(true);
    if (!validateForm()) {
      setSaveLoading(false);
      return;
    }
    if (lead?.kae?.id === null) {
      setSaveLoading(false);
      toast.error(`Please select a Kae.`, { ...toastAttr, autoClose: 4000 });
      return;
    }
    if (lead?.mandap?.id === null) {
      setSaveLoading(false);
      toast.error(`Please select a mandap.`, { ...toastAttr, autoClose: 4000 });
      return;
    }
    if (lead?.leadEvents?.length === 0) {
      setSaveLoading(false);
      toast.error(`Please add at least one event to the lead.`, {
        ...toastAttr,
        autoClose: 4000,
      });
      return;
    }

    e.preventDefault();

    const isEditMode = location?.state?.editMode;
    const leadId = location?.state?.id;
    const apiUrl = isEditMode
      ? `${config.service}/masters/offline_leads/update/${leadId}/`
      : `${config.service}/masters/offline_leads/create/`;

    const { leadEvents } = lead;
    const { leadEvents: originalLeadEvents } = originalData;

    const { updatedEvents, removedEvents } = detectChanges(
      originalLeadEvents,
      leadEvents
    );

    try {
      // Check availability for new/altered events
      for (const event of updatedEvents) {
        try {
          const availability = await checkAvailability(
            event,
            lead.mandap.id,
            isEditMode,
            tokenConfig
          );

          if (!availability.isAvailable) {
            showToastForUnavailableDates(
              event.event_date_from,
              availability.blockedTimes
            );
            setSaveLoading(false);
            return; // Stop further processing
          }
        } catch (error) {
          console.error(
            `Error checking availability for event on ${
              event.event_date_from
            }:${error.response?.data || error.message}`,
            error.response?.data || error.response?.data.message
          );
          setSaveLoading(false);
          // Show a generic error message if the API call itself fails unexpectedly
          toast.error(
            `Failed to check availability for event on ${event.event_date_from}. Please try again.`,
            { ...toastAttr, autoClose: 4000 }
          );
          return; // Stop further processing
        }
      }

      const dataToSend = {
        kae: lead.kae,
        mandap: lead.mandap,
        customer_name: lead.customerName,
        contact_number: lead.contactNumber,
        address: lead.address,
        pincode: lead.pincode,
        remarks: lead.remarks,
        email: lead.email,
        lead_events: leadEvents.filter(
          (event) => !removedEvents.some((removed) => removed.id === event.id)
        ),
      };

      // let booking_id = null;
      try {
        const response = await axios({
          method: isEditMode ? "put" : "post",
          url: apiUrl,
          data: dataToSend,
          ...tokenConfig,
        });

        if (response.status === 200 || response.status === 201) {
          console.log("Lead saved successfully", response.data);
        }
      } catch (error) {
        if (error.response) {
          setSaveLoading(false);
          console.error("Error response:", error.response.data);
          if (
            error.response.data.errors &&
            error.response.data.errors.contact_number
          ) {
            toast.error(
              "Error: " + error.response.data.errors.contact_number.join(", "),
              { ...toastAttr, autoClose: 4000 }
            );
            return;
          } else {
            toast.error(
              "An error occurred: " + error.response.data.message ||
                "Unknown error",
              { ...toastAttr, autoClose: 4000 }
            );
            return;
          }
        } else if (error.request) {
          console.error("Error request:", error.request);
          toast.error("Network error: No response received from the server.", {
            ...toastAttr,
            autoClose: 4000,
          });
          setSaveLoading(false);
          return;
        } else {
          console.error("Error:", error.message);
          toast.error("Error: " + error.message, {
            ...toastAttr,
            autoClose: 4000,
          });
          setSaveLoading(false);
          return;
        }
      }

      // Allocate Mandap for updated events
      for (const event of updatedEvents) {
        await allocateMandap(lead.mandap.id, event, true, null, tokenConfig);
      }

      // Unallocate Mandap for removed events
      for (const event of removedEvents) {
        await allocateMandap(lead.mandap.id, event, false, null, tokenConfig);
      }

      toast.success(
        `Lead ${
          isEditMode ? "updated" : "created"
        } successfully and Mandap allocated!`,
        { ...toastAttr, autoClose: 4000 }
      );
      setSaveLoading(false);
      history.push({ pathname: "/offlineleads" });
    } catch (error) {
      setSaveLoading(false);
      console.error(
        "Error during submission:",
        error.response?.data || error.message
      );
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again.",
        { ...toastAttr, autoClose: 4000 }
      );
    }
  };

  const handleEntitySelection = (selectedOption, type) => {
    console.log("selectedOption", selectedOption);
    console.log("type", type);
    setLead({ ...lead, [type]: selectedOption });
  };

  const fetchAllOptions = async (type, query) => {
    if (type === "kae") setKaeLoading(true);
    else if (type === "mandap") setMandapLoading(true);
    const options = await fetchOptions(type, query, tokenConfig);

    if (type === "kae") setKaeLoading(false);
    else if (type === "mandap") setMandapLoading(false);

    if (type === "kae") setKaeOptions(options);
    else if (type === "mandap") setMandapOptions(options);
  };

  const breadcrumbData = [
    { title: "Offline Leads", href: "/offlineleads" },
    {
      title: editMode ? "Edit Offline Lead" : "Create Offline Lead",
      href: "#",
    },
  ];

  if (loading)
    return (
      <Container
        className="w-100 d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Container>
    );

  return (
    // <Container>
    //   <Breadcrumbs data={breadcrumbData} />
    //   <Card>
    //     <Card.Header>
    //       <div className="d-flex align-items-center">
    //         <Button
    //           variant="primary"
    //           onClick={handleSubmit}
    //           disabled={saveLoading} // Disable button while loading
    //           className="d-flex align-items-center justify-content-center"
    //           style={{ position: "relative" }}
    //         >
    //           {/* {saveLoading && (
    //           <div
    //             className="position-absolute"
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //             }}
    //           >
    //             <CircularProgress size={24} />
    //           </div>
    //         )} */}
    //           {saveLoading ? "Saving..." : "Save"}
    //         </Button>
    //         <Button
    //           variant="secondary"
    //           className="ms-2"
    //           onClick={() => history.push({ pathname: "/offlineleads" })}
    //         >
    //           Cancel
    //         </Button>
    //       </div>
    //     </Card.Header>
    //     <Card.Body>
    //       <Fieldset title="Lead Details">
    //         <Col md={6}>
    //           <Form.Group controlId="kae">
    //             <Form.Label>KAE Name</Form.Label>
    //             <SearchableDropdown
    //               placeholder="Search and select KAE..."
    //               options={kaeOptions}
    //               value={lead.kae}
    //               onSearch={(query) => fetchAllOptions("kae", query)}
    //               onSelect={(selectedOption) =>
    //                 handleEntitySelection(selectedOption, "kae")
    //               }
    //               isLoading={kaeLoading}
    //             />
    //           </Form.Group>
    //         </Col>

    //         <Col md={6}>
    //           <Form.Group controlId="mandap">
    //             <Form.Label>Mandap</Form.Label>
    //             <div className="d-flex align-items-end ">
    //               <SearchableDropdown
    //                 placeholder="Search and select Mandap..."
    //                 options={mandapOptions}
    //                 value={lead.mandap}
    //                 onSearch={(query) => fetchAllOptions("mandap", query)}
    //                 onSelect={(selectedOption) =>
    //                   handleEntitySelection(selectedOption, "mandap")
    //                 }
    //                 isLoading={mandapLoading}
    //               />
    //               <LeadEvents
    //                 mandapId={lead?.mandap?.id || null}
    //                 onUpdateEvents={(events) =>
    //                   handleInputChange("leadEvents", events)
    //                 }
    //                 LeadEventData={lead?.leadEvents || []}
    //               />
    //             </div>
    //             <div>
    //               {lead?.leadEvents?.length > 0 && (
    //                 <div>
    //                   <p className="mt-2">
    //                     <strong>Booked Slots:</strong>
    //                     <ul>
    //                       {lead?.leadEvents?.map((event, index) => (
    //                         <li key={index}>
    //                           {event?.event_date_from || event?.date} -{" "}
    //                           {event.event_date_to || event?.date}
    //                           {`(${event?.booking_type})`}
    //                         </li>
    //                       ))}
    //                     </ul>
    //                   </p>
    //                 </div>
    //               )}
    //             </div>
    //           </Form.Group>
    //         </Col>
    //       </Fieldset>
    //       <Fieldset title="Mandap Information">
    //         {formFields.slice(0, 4).map((field) => (
    //           <Col md={6} key={field.name}>
    //             <Form.Group controlId={field.name}>
    //               <Form.Label>{field.label}</Form.Label>
    //               <Form.Control
    //                 type={field.type}
    //                 disabled
    //                 value={lead?.mandap[field.name]}
    //                 onChange={(e) =>
    //                   handleInputChange(field.name, e.target.value)
    //                 }
    //                 // isInvalid={errors[field.name]}
    //               />
    //               {/* <Form.Control.Feedback type="invalid">
    //                 {errors[field.name]}
    //               </Form.Control.Feedback> */}
    //             </Form.Group>
    //           </Col>
    //         ))}
    //       </Fieldset>

    //       <Fieldset title="Customer Information">
    //         {formFields.slice(4, 9).map((field) => (
    //           <Col md={6} key={field.name}>
    //             <Form.Group controlId={field.name}>
    //               <Form.Label>{field.label}</Form.Label>
    //               <Form.Control
    //                 type={field.type}
    //                 value={lead[field.name]}
    //                 onChange={(e) =>
    //                   handleInputChange(field.name, e.target.value)
    //                 }
    //                 isInvalid={errors[field.name]}
    //               />
    //               <Form.Control.Feedback type="invalid">
    //                 {errors[field.name]}
    //               </Form.Control.Feedback>
    //             </Form.Group>
    //           </Col>
    //         ))}
    //       </Fieldset>

    //       <Fieldset title="Remarks">
    //         <Col md={12}>
    //           <Form.Group controlId="remarks">
    //             <Form.Label>Remarks</Form.Label>
    //             <Form.Control
    //               as="textarea"
    //               rows={5}
    //               value={lead.remarks}
    //               onChange={(e) => handleInputChange("remarks", e.target.value)}
    //               isInvalid={errors["remarks"]}
    //             />
    //             <Form.Control.Feedback type="invalid">
    //               {errors["remarks"]}
    //             </Form.Control.Feedback>
    //           </Form.Group>
    //         </Col>
    //       </Fieldset>
    //     </Card.Body>
    //   </Card>
    //   <ToastContainer
    //     position="top-center"
    //     autoClose={3000}
    //     hideProgressBar
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //   />
    // </Container>
    <Container>
      <Breadcrumbs data={breadcrumbData} />
      <Card>
        <Card.Header>
          <div className="d-flex  flex-sm-row align-items-center justify-content-start">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={saveLoading}
              className="d-flex align-items-center justify-content-center  "
            >
              {saveLoading ? "Saving..." : "Save"}
            </Button>
            <Button
              variant="secondary"
              className=""
              onClick={() => history.push({ pathname: "/offlineleads" })}
            >
              Cancel
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Fieldset title="Lead Details">
            <Col md={6} sm={12}>
              <Form.Group controlId="kae">
                <Form.Label>Agent Name</Form.Label>
                <SearchableDropdown
                  placeholder="Search and select Agent..."
                  options={kaeOptions}
                  value={lead.kae}
                  onSearch={(query) => fetchAllOptions("kae", query)}
                  onSelect={(selectedOption) =>
                    handleEntitySelection(selectedOption, "kae")
                  }
                  isLoading={kaeLoading}
                  disabled={false}
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={12}>
              <Form.Group controlId="mandap">
                <Form.Label>Mandap</Form.Label>
                <div className="d-flex flex-column flex-sm-row align-items-end">
                  <SearchableDropdown
                    placeholder="Search and select Mandap..."
                    options={mandapOptions}
                    value={lead.mandap}
                    onSearch={(query) => fetchAllOptions("mandap", query)}
                    onSelect={(selectedOption) =>
                      handleEntitySelection(selectedOption, "mandap")
                    }
                    isLoading={mandapLoading}
                    disabled={editMode ? true : false}
                  />
                  <LeadEvents
                    mandapId={lead?.mandap?.id || null}
                    onUpdateEvents={(events) =>
                      handleInputChange("leadEvents", events)
                    }
                    LeadEventData={lead?.leadEvents || []}
                  />
                </div>
                {lead?.leadEvents?.length > 0 && (
                  <div className="mt-2">
                    <strong>Booked Slots:</strong>
                    <ul>
                      {lead?.leadEvents?.map((event, index) => (
                        <li key={index}>
                          {event?.event_date_from || event?.date} -{" "}
                          {event.event_date_to || event?.date}{" "}
                          {`(${event?.booking_type})`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Form.Group>
            </Col>
          </Fieldset>

          <Fieldset title="Mandap Information">
            {formFields.slice(0, 4).map((field) => (
              <Col md={6} key={field.name}>
                <Form.Group controlId={field.name}>
                  <Form.Label>{field.label}</Form.Label>
                  <Form.Control
                    type={field.type}
                    disabled
                    value={lead?.mandap[field.name]}
                    onChange={(e) =>
                      handleInputChange(field.name, e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            ))}
          </Fieldset>

          <Fieldset title="Customer Information">
            {formFields.slice(4, 9).map((field) => (
              <Col md={6} sm={12} key={field.name}>
                <Form.Group controlId={field.name}>
                  <Form.Label>{field.label}</Form.Label>
                  <Form.Control
                    type={field.type}
                    value={lead[field.name]}
                    onChange={(e) =>
                      handleInputChange(field.name, e.target.value)
                    }
                    isInvalid={errors[field.name]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field.name]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            ))}
          </Fieldset>

          <Fieldset title="Remarks">
            <Col md={12}>
              <Form.Group controlId="remarks">
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={lead.remarks}
                  onChange={(e) => handleInputChange("remarks", e.target.value)}
                  isInvalid={errors["remarks"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["remarks"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Fieldset>
        </Card.Body>
      </Card>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default OfflineLeadsForm;
