import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Container, Row, CardBody } from "reactstrap";
import axios, { get } from "axios";
import Mandapam from "./views/mandapam";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import { Typography } from "@mui/material";

import { IconButton, Box } from "@mui/material";
import { IoArrowBack, IoClose } from "react-icons/io5";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AppBar, Toolbar, Paper } from "@mui/material";
import { IoEye } from "react-icons/io5";
import ConfirmationModel from "../../../shared/ConfirmationModel";
// import MandapamPending from "./views/mandapam_pending";

import { Button, Form, Modal, Table } from "react-bootstrap";
import Select from "react-select";
import CustomSwitch from "../../Helper/CustomSwitch";
import Loader from "../../Helper/Loader";
// import useDebounce from "../../../CustomHooks/useDebounce";
import TextField from "@mui/material/TextField";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { HiRefresh } from "react-icons/hi";
import { Autocomplete } from "@mui/material";
import { RxReset } from "react-icons/rx";
import EmbedMap from "./views/Embed";
import useDebounce from "../../CustomHooks/useDebounce";
import { CircularProgress } from "@material-ui/core";
import ReportContainer from "./report/ReportContainer";
// import EmbedMap from "../Embed";

const MandapCard = ({ mandap, onViewClick, onRejectClick, showView }) => {
  console.log("mandapmandap", mandap);

  const [rejectConfirm, setRejectConfirmation] = useState(false);

  return (
    <Paper
      sx={{
        width: "100%",
        maxHeight: 200,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        padding: 2,
        boxShadow: 3,
        marginBottom: "15px",
        borderRadius: "10px",
        marginRight: "10%",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" component="div">
          {mandap?.mandap_name}
        </Typography>
        <div>Created By: {mandap?.created_by_username}</div>
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            backgroundColor: "#FF9800", // Changed color for visibility
            color: "white",
            padding: "2px 8px", // Adjusted padding for better appearance
            borderRadius: "8px",
            fontSize: "0.6rem", // Slightly larger font size
          }}
        >
          Waiting for Approval
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: 1,
          display: "flex",
          gap: 1,
          background: "white",
        }}
      >
        <Box
          disabled={!showView}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "primary.main",
            color: "white",
            padding: "2px 8px", // Added more padding for better appearance
            borderRadius: "4px",
            cursor: showView ? "pointer" : "none",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            if (showView) onViewClick(mandap);
          }}
        >
          {/* <IoEye style={{ marginRight: 4 }} />
                  View */}
          {!showView ? (
            <>
              <CircularProgress
                size={15} // Adjust size as needed
                sx={{ color: "white", paddingRight: "4px" }} // Adjust color to match your theme
                paddingY={2}
              />{" "}
              Loading
            </>
          ) : (
            <>
              <IoEye style={{ marginRight: 4 }} />
              View
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "error.main",
            color: "white",
            padding: "2px 8px", // Added more padding for better appearance
            borderRadius: "4px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "error.dark",
            },
          }}
          onClick={() => setRejectConfirmation(true)}
        >
          <IoClose style={{ marginRight: 4 }} />
          Reject
        </Box>
        <ConfirmationModel
          operation={"Remove"}
          handleConfirmation={async () => {
            await onRejectClick(mandap);
            setRejectConfirmation(false);
          }}
          val={rejectConfirm}
          modelName={"Reject"}
          onclose={() => {
            setRejectConfirmation(false);
          }}
        />
      </Box>
    </Paper>
  );
};
const VedorMandapPending = (props) => {
  // TODO NEED TO CHANGE THE PROPS STATE
  let propsState = props.history.location.state;

  let breadcrumbData = [
    {
      title: propsState && propsState.tabValue ? "Mandap" : "Vendor",
      href: propsState && propsState.tabValue ? "/mandapList" : "/vendor",
    },
    {
      title: "Pending",
      href: "#",
    },
  ];

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };

  const [approvePendingMandaps, setApprovePendingMandap] = useState([]);
  const [overlay, setoverlay] = useState(false);
  // const [mandap, setMandap] = useState({});
  const [loading, setloading] = useState({});
  const [errors, setErrors] = useState({});

  const [loadedAllData, setLoadedALlData] = useState(false);

  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };

  const saveMap = (data) => {
    setSelectedMandap({
      ...selectedMandap,
      latitude: data.lat,
      longitude: data.lng,
    });
  };

  const setFormDetails = (data, keyName) => {
    console.log(data, keyName, "datadatadata");

    // setForm({
    //   ...form,
    //   ...data,
    // });
    // if (keyName && !!errors[keyName])
    //   setErrors({
    //     ...errors,
    //     [keyName]: null,
    //   });
  };
  const setMandapDetails = (data, keyName) => {
    console.log(data, keyName, "datadatadata");
    setSelectedMandap({
      ...selectedMandap,
      ...data,
    });
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };
  const [selectedMandap, setSelectedMandap] = useState(null);
  const [oldMandapData, setOldMandapData] = useState(null);
  const [pendingMandapData, setPendingMandapData] = useState(null);

  const [loadingState, setLoadingState] = useState(loading);
  const [overlayState, setOverlayState] = useState(overlay);
  const [modifiedData, setModifiedData] = useState([]);
  const [modifiedKeys, setModifiedKeys] = useState([]);

  console.log("pendingMandapData", pendingMandapData);
  console.log(oldMandapData, "oldMandapData");

  console.log("modifiedData", modifiedKeys, modifiedData);

  const onViewClick = async (mandap) => {
    console.log("mandapmandapmandap", mandap);
    if (mandap && mandap?.vendor_mandap_table_id) {
      setLoadingState(true);
      await get(
        config.service +
          "/masters/vendor_mandap/" +
          mandap?.vendor_mandap_table_id +
          "/",
        tokenKey
      )
        .then((res) => {
          console.log("api sucess", res?.data);
          let temp = res?.data;
          temp.classification_name = classificationList.find(
            (temp) => temp.value === res?.data.mandap_classification_id
          )?.label;
          temp.area_name = areaList.find(
            (temp) => temp.value === res?.data.area_id
          )?.label;
          temp.city_name = cityList.find(
            (temp) => temp.value === res?.data.city_id
          )?.label;
          temp.state_name = stateList.find(
            (temp) => temp.value === res?.data.state_id
          )?.label;
          temp.mandap_type_name = typeList.find(
            (temp) => temp.value === res?.data.mandap_type_id //mandap_type_name
          )?.label;
          //           const inclPriceList = JSON.parse(temp?.price_details?.incl_price || "[]");
          // console.log("Parsed incl_price:", inclPriceList);

          // // Ensure include_list, exclude_list, and price_details exist
          // console.log("Include list:", temp?.price_details?.include_list);
          // console.log("Exclude list:", temp?.price_details?.exclude_list);
          // console.log("Price details:", temp?.price_details);

          // // Process each item in inclPriceList
          // inclPriceList?.forEach((item) => {
          //   console.log("Processing item:", item);

          //   // Check price_details for a matching name
          //   const priceDetailItem = temp?.price_details?.find?.(
          //     (detail) => detail.id === item.id
          //   );

          //   if (priceDetailItem) {
          //     item.name = priceDetailItem.name;
          //     console.log(`Matched in price_details: ${item.name}`);
          //   } else {
          //     console.log("Not found in price_details, checking include_list...");

          //     // Fallback to include_list
          //     const includeItem = temp?.price_details?.include_list?.find(
          //       (incl) => incl.id === item.id
          //     );

          //     if (includeItem) {
          //       item.name = includeItem.name;
          //       console.log(`Matched in include_list: ${item.name}`);
          //     } else {
          //       console.log("Not found in include_list, checking exclude_list...");

          //       // Fallback to exclude_list
          //       const excludeItem = temp?.price_details?.exclude_list?.find(
          //         (excl) => excl.id === item.id
          //       );

          //       item.name = excludeItem?.name || "Unknown";
          //       console.log(
          //         excludeItem
          //           ? `Matched in exclude_list: ${item.name}`
          //           : `Defaulted to Unknown`
          //       );
          //     }
          //   }
          // });

          // // Add updated incl_price list back to temp
          // temp.price_details.incl_price = JSON.stringify(inclPriceList)
          // console.log("Final incl_price_list:", temp);

          const flatmandap = flattenPriceDetails({ ...mandap });
          const flattemp = flattenPriceDetails({ ...temp });

          setModifiedKeys(compareKeys(flatmandap, flattemp));
          setModifiedData(compareObjects(flatmandap, flattemp));
          setPendingMandapData(flatmandap);
          setOldMandapData(flattemp);
          setLoadingState(false);
          console.log(temp, "0000000000");
        })
        .catch((err) => {
          if (err?.response?.status === 401) window.location.href = "/";
          props?.setLoader(false);
          setLoadingState(false);
        });
    }
    setSelectedMandap(mandap);
  };

  const handleBackClick = () => {
    setSelectedMandap(null);
  };

  const onRejectClick = async (mandap) => {
    let data = {
      is_rejected: true,
    };
    await axios({
      url:
        config.service +
        "/masters/vendor_mandap_pending/" +
        mandap?.id +
        "/reject",
      method: "PATCH",
      headers: {
        Authorization: "Token " + sessionStorage.getItem("tokenKey"),
      },
      data: data,
    }).then((res) => {
      toast.success("Rejected successfully", toastAttr, res);
    });
    getMandapPendingList();
    // axios.patch(config.service + "/masters/vendor_mandap_pending/" +mandap?.id+"/reject",data)
    // .then((res) => {
    //   console.log('res',res)

    // });
  };

  function flattenPriceDetails(obj) {
    if (obj.price_details && typeof obj.price_details === "object") {
      const priceDetails = obj.price_details;

      for (const [key, value] of Object.entries(priceDetails)) {
        if (key === "images" && Array.isArray(value)) {
          // Flatten the images key as an array of links
          obj[`price_details_${key}`] = value;
        } else {
          obj[`price_details_${key}`] = value;
        }
      }

      delete obj.price_details; // Remove the original nested object
    }

    return obj;
  }

  function compareKeys(obj1, obj2) {
    console.log(obj1, obj2, "}}");
    const excludedKeys = new Set([
      "created_by_id",
      "id",
      "created_on",
      "vendor_name",
      "is_approved",
      "is_edited",
      "is_rejected",
      "is_seo_tags",
      "reservation_price",
      "updated_on",
      "vendor_mandap_table_id",
      "created_by_username",
      "approved_by_id",
      "rejected_by_id",
      "updated_by_username",
      "is_price_details",
      "price_id",
      "price_details_id",
      "price_details_created_on",
      "price_details_updated_on",
      "price_details_is_approved",
      "price_details_is_edited",
      "price_details_is_rejected",
      "price_details_created_by_id",
      "price_details_updated_by_id",
      "price_details_main_price_id",
      "price_details",
    ]);

    const changes = [];
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    keys.forEach((key) => {
      if (excludedKeys.has(key)) return;

      const isArray1 = Array.isArray(obj1[key]);
      const isArray2 = Array.isArray(obj2[key]);

      if (isArray1 || isArray2) {
        // Handle cases where one array is null or undefined
        const arr1 = obj1[key] || [];
        const arr2 = obj2[key] || [];

        // Compare arrays (specifically for "price_details_images")
        if (key === "price_details_images") {
          if (
            arr1.length !== arr2.length ||
            !arr1.every((val, index) => val === arr2[index])
          ) {
            changes.push(key);
          }
        }
      } else {
        const isPrimitive1 =
          typeof obj1[key] !== "object" || obj1[key] === null;
        const isPrimitive2 =
          typeof obj2[key] !== "object" || obj2[key] === null;

        if (isPrimitive1 && isPrimitive2) {
          if (obj1[key] !== obj2[key]) {
            changes.push(key);
          }
        }
      }
    });

    return changes;
  }

  // Function to compare objects and return detailed changes
  function compareObjects(obj1, obj2) {
    console.log(obj1, obj2, "[[[[[[");

    const excludedKeys = new Set([
      "created_by_id",
      "id",
      "created_on",
      "vendor_name",
      "is_approved",
      "is_edited",
      "is_rejected",
      "is_seo_tags",
      "reservation_price",
      "updated_on",
      "vendor_mandap_table_id",
      "created_by_username",
      "approved_by_id",
      "rejected_by_id",
      "updated_by_username",
      "is_price_details",
      "price_id",
      "price_details_id",
      "price_details_created_on",
      "price_details_updated_on",
      "price_details_is_approved",
      "price_details_is_edited",
      "price_details_is_rejected",
      "price_details_created_by_id",
      "price_details_updated_by_id",
      "price_details_main_price_id",
      "price_details",
    ]);

    const changes = [];
    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    keys.forEach((key) => {
      if (excludedKeys.has(key)) return;

      const isArray1 = Array.isArray(obj1[key]);
      const isArray2 = Array.isArray(obj2[key]);

      if (isArray1 || isArray2) {
        // Handle cases where one array is null or undefined
        const arr1 = obj1[key] || [];
        const arr2 = obj2[key] || [];

        // Compare arrays (specifically for "price_details_images")
        if (key === "price_details_images") {
          if (
            arr1.length !== arr2.length ||
            !arr1.every((val, index) => val === arr2[index])
          ) {
            changes.push({
              key,
              oldValue: arr2,
              newValue: arr1,
            });
          }
        }
      } else {
        const isPrimitive1 =
          typeof obj1[key] !== "object" || obj1[key] === null;
        const isPrimitive2 =
          typeof obj2[key] !== "object" || obj2[key] === null;

        if (isPrimitive1 && isPrimitive2) {
          if (obj1[key] !== obj2[key]) {
            changes.push({
              key,
              oldValue: obj2[key],
              newValue: obj1[key],
            });
          }
        }
      }
    });

    return changes;
  }

  console.log("approvePendingMandaps", approvePendingMandaps);

  console.log("mandap123456", selectedMandap);

  useEffect(() => {
    getMandapPendingList();
  }, []);

  const getMandapPendingList = () => {
    get(config.service + "/masters/vendor_mandap_pending/", tokenKey)
      .then((res) => {
        setApprovePendingMandap(res?.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) window.location.href = "/";
        props?.setLoader(false);
      });
  };

  async function convertImagesToBase64(images) {
    if (!images || images.length === 0) {
      return [];
    }

    const base64Images = [];

    for (let i = 0; i < images.length; i++) {
      try {
        const base64Image = await convertImageUrlToBase64(images[i]);
        base64Images.push(base64Image);
      } catch (error) {
        console.error(`Error converting image ${images[i]}:`, error);
        base64Images.push(null); // Add null or handle error as needed
      }
    }

    return base64Images;
  }
  function convertImageUrlToBase64(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Allow cross-origin images

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Convert canvas to Base64
        const base64 = canvas.toDataURL();
        resolve(base64);
      };

      img.onerror = (err) => reject(err);

      img.src = url;
    });
  }

  const handleApproveClick = async () => {
    const approve = { is_approved: false };
    const vendorMandapId = selectedMandap?.id;
    const editMode = selectedMandap?.is_edited;
    const data = {
      ...selectedMandap,
      id: selectedMandap?.vendor_mandap_table_id,
      price_id: selectedMandap?.price_details?.main_price_id || null,
    };
  
    delete data["is_approved"];
    delete data["is_rejected"];
    delete data["is_edited"];
  
    const url = editMode
      ? `${config.service}/masters/vendor_mandap/${selectedMandap?.vendor_mandap_table_id}/`
      : `${config.service}/masters/vendor_images/create/`;
  
    try {
      // Save mandap data
      await axios({
        url,
        method: editMode ? "PUT" : "POST",
        headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
        data,
      });
  
      // Save pricing data if price_details is present
      if (selectedMandap?.price_details) {
        const pricingUrl = selectedMandap?.price_details?.main_price_id
          ? `${config.service}/masters/mandap_pricing/update/${selectedMandap.price_details.main_price_id}/`
          : `${config.service}/masters/mandap_pricing/create/`;
  
        const pricingData = {
          ...selectedMandap.price_details,
          mandap_id: selectedMandap?.vendor_mandap_table_id,
          include_list: selectedMandap.price_details.include_list?.map(item => item.id) || [],
          exclude_list: selectedMandap.price_details.exclude_list?.map(item => item.id) || [],
          incl_price: data.price_details?.incl_price || "[]",
          pricingInclExclDetails: [
            ...(selectedMandap.price_details.include_list?.map(item => ({
              list_item_id: item.id,
              price_value: item.price_value || 0,
              price_type: "include",
            })) || []),
            ...(selectedMandap.price_details.exclude_list?.map(item => ({
              list_item_id: item.id,
              price_value: item.price_value || 0,
              price_type: "exclude",
            })) || []),
          ],
        };
  
        await axios({
          url: pricingUrl,
          method: selectedMandap.price_details.main_price_id ? "PUT" : "POST",
          headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
          data: pricingData,
        });
      }
  
      // Approve the mandap
      await axios({
        url: `${config.service}/masters/vendor_mandap_pending/${vendorMandapId}/approve`,
        method: "PATCH",
        headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
        data: approve,
      });
  
      await getMandapPendingList();
      setSelectedMandap(null);
      toast.success("Approved successfully", toastAttr);
    } catch (err) {
      setLoader(false);
      if (err?.response?.status === 401) window.location.href = "/";
      else toast.error("Saving Failed", toastAttr);
    }
  };
  
  const [mandap, setMandap] = useState(props.mandap);
  const [readOnly, setReadOnly] = useState(false);

  const [showChanges, setShowChanges] = useState(false);

  const [classificationList, setclassificationList] = useState([]);
  const [areaList, setareaList] = useState([]);
  const [areaData, setareaData] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [typeList, settypeList] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Define how many items to display per page

  const totalPages = Math.ceil(approvePendingMandaps.length / itemsPerPage);
  const paginatedData = approvePendingMandaps.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const [entryTime, setentryTime] = useState(null);
  // const [exitTime, setexitTime] = useState(null);

  console.log(
    "1111>>>>>>>>>>>>>",
    classificationList,
    areaList,
    areaData,
    cityList,
    cityData,
    stateList,
    typeList
  );

  console.log(
    "1111111111111",
    "\nareaList : ",
    areaList,
    "\nareaData : ",
    areaData,
    "\ncityList : ",
    cityList,
    "\ncityData : ",
    cityData
  );
  console.log("2222222222222\nstateList:", stateList, "\ntypeList:", typeList);
  const levels = [
    { value: "ground_level", label: "Ground Level" },
    { value: "level1", label: "Level 1" },
    { value: "level2", label: "Level 2" },
    { value: "level3", label: "Level 3" },
    { value: "level4", label: "Level 4" },
    { value: "level5", label: "Level 5" },
  ];
  const fuels = [
    { value: "firewood", label: "Firewood" },
    { value: "gas", label: "Gas" },
  ];
  const ACTypes = [
    { value: "ac", label: "AC" },
    { value: "non_ac", label: "Non AC" },
  ];
  const foodTypes = [
    { value: "veg", label: "Veg" },
    { value: "non_veg", label: "Non Veg" },
    { value: "both", label: "Both" },
  ];

  const defaultTypes = { levels, fuels, ACTypes, foodTypes };

  useEffect(() => {
    get(config.service + "/masters/mandap_classification/", tokenKey)
      .then((res) => {
        let classification = [];
        for (let i in res.data)
          classification.push({
            label: res.data[i].classification_name,
            value: res.data[i].id,
          });
        setclassificationList(classification);
        get(config.service + "/masters/mandap_type/", tokenKey).then((res) => {
          let type = [];
          for (let i in res.data)
            type.push({ label: res.data[i].name, value: res.data[i].id });
          settypeList(type);
          get(config.service + "/masters/area/list/", tokenKey).then((res) => {
            let area = [];
            for (let i in res.data)
              area.push({ label: res.data[i].name, value: res.data[i].id });
            setareaList(area);
            setareaData(res.data);
            get(config.service + "/masters/city/", tokenKey).then((res) => {
              let city = [];
              for (let i in res.data)
                city.push({ label: res.data[i].name, value: res.data[i].id });
              setcityList(city);
              setcityData(res.data);
              get(config.service + "/masters/state/", tokenKey).then((res) => {
                let state = [];
                for (let i in res.data)
                  state.push({
                    label: res.data[i].name,
                    value: res.data[i].id,
                  });
                setstateList(state);
                setLoadedALlData(true);
                get(config.service + "/masters/base/vendor/", tokenKey).then(
                  (res) => {
                    let vendor = [];
                    res?.data?.results?.map((obj) => {
                      vendor.push({ value: obj.id, label: obj.vendor_name });
                    });
                  }
                );
              });
            });
          });
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/";
        // props.setLoader(false);
      });
  }, []);
  const processInclPrice = (selectedmandap) => {
    if (selectedmandap) {
      const inclPrice = JSON.parse(
        selectedmandap?.price_details_incl_price || "[]"
      );
      const includeList = selectedmandap?.price_details_include_list || [];
      const excludeList = selectedmandap?.price_details_exclude_list || [];

      // Function to get the name from include/exclude lists
      const getNameById = (id, list) =>
        list.find((item) => item.id === id)?.name || "Unknown";

      // Separate processed data into include and exclude categories
      const processedData = inclPrice.map((item) => {
        const nameFromInclude = getNameById(item.id, includeList);
        const nameFromExclude = getNameById(item.id, excludeList);

        return {
          id: item.id,
          price: item.price,
          category: nameFromInclude !== "Unknown" ? "include" : "exclude",
          name:
            nameFromInclude !== "Unknown" ? nameFromInclude : nameFromExclude,
        };
      });

      return processedData;
    }

    return [];
  };

  function renderHyperlinks(links = []) {
    if (!Array.isArray(links) || links.length === 0) {
      return "No links available";
    }

    return (
      <ul>
        {links.map((link, index) => (
          <li key={index} style={{ marginBottom: "10px" }}>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                textAlign: "center",
                color: "#007BFF",
                textDecoration: "none",
                marginBottom: "5px",
              }}
            >
              Open Image
            </a>
            <div
              style={{
                width: "100px",
                height: "100px",
                overflow: "hidden",
                borderRadius: "8px",
                margin: "0 auto",
              }}
            >
              <img
                src={link}
                alt={`Image ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              {/* <div className="card__title pageNav"> */}
              {/* change heading */}
              <div className="bold-text">
                <Breadcrumbs data={breadcrumbData} />
              </div>
              <div>
                {!loadedAllData ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh", // Full height of the viewport
                      backgroundColor: "#f5f5f5", // Light background for the loader
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                ) : selectedMandap ? (
                  <>
                    <AppBar
                      position="static"
                      sx={{
                        backgroundColor: "#fff",
                        boxShadow: "none",
                      }}
                    >
                      <Toolbar>
                        <IconButton
                          edge="start"
                          color="default"
                          aria-label="back"
                          onClick={handleBackClick}
                        >
                          <IoArrowBack color="black" />
                        </IconButton>

                        <Typography
                          variant="h6"
                          sx={{ flexGrow: 1, fontSize: "1rem" }}
                        >
                          Mandap Details
                        </Typography>

                        <Box
                          onClick={() => setShowChanges(true)}
                          sx={{
                            backgroundColor: "#2196F3",
                            color: "white",
                            paddingX: "8px",
                            paddingY: "4px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            "&:hover": {
                              backgroundColor: "#1e88e5",
                              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                            },
                            marginRight: "10px",
                          }}
                        >
                          Changes
                        </Box>
                        <Box
                          onClick={handleApproveClick}
                          sx={{
                            backgroundColor: "#2196F3",
                            color: "white",
                            paddingX: "8px",
                            paddingY: "4px",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            "&:hover": {
                              backgroundColor: "#1e88e5",
                              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                            },
                          }}
                        >
                          Approve
                        </Box>
                      </Toolbar>
                    </AppBar>

                    <div style={{ padding: 20 }}>
                      {oldMandapData && (
                        <MandapamPending
                          defaultTypes={defaultTypes}
                          mandap={selectedMandap}
                          propsData={propsState}
                          loading={loadingState}
                          overlay={overlayState}
                          save={setMandapDetails}
                          errors={errors}
                          setLoader={setLoadingState}
                          saveMap={saveMap}
                          classificationList={classificationList}
                          areaList={areaList}
                          areaData={areaData}
                          cityList={cityList}
                          cityData={cityData}
                          stateList={stateList}
                          typeList={typeList}
                          oldMandapData={oldMandapData}
                          modifiedKeys={modifiedKeys}
                        />
                      )}
                    </div>
                  </>
                ) : 
                
                // approvePendingMandaps?.length ? (
                //   approvePendingMandaps.map((eachMandap, index) => (
                //     <MandapCard
                //       key={index}
                //       onViewClick={onViewClick}
                //       onRejectClick={onRejectClick}
                //       mandap={eachMandap}
                //       showView={loadedAllData}
                //     />
                //   ))
                // ) : (
                //   <Box
                //     sx={{
                //       height: "400px",
                //       display: "flex",
                //       alignItems: "center",
                //       justifyContent: "center",
                //       backgroundColor: "#f5f5f5",
                //       borderRadius: "8px",
                //       boxShadow: 2,
                //       textAlign: "center",
                //       padding: 2,
                //     }}
                //   >
                //     <Typography
                //       variant="h6"
                //       component="div"
                //       sx={{
                //         color: "#757575",
                //         fontWeight: "bold",
                //       }}
                //     >
                //       No Pending Approvals at the moment.
                //     </Typography>
                //   </Box>
                // )
                (
                 approvePendingMandaps?.length ? (
                      <>
                        {paginatedData.map((eachMandap, index) => (
                          <MandapCard
                            key={index}
                            onViewClick={onViewClick}
                            onRejectClick={onRejectClick}
                            mandap={eachMandap}
                            showView={loadedAllData}
                          />
                        ))}
              
                        {/* Pagination Controls */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                            marginTop: 2,
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            sx={{ marginRight: 1 }}
                          >
                            Previous
                          </Button>
                          <Typography variant="body2" sx={{ marginX: 2 }}>
                            Page {currentPage} of {totalPages}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            sx={{ marginLeft: 1 }}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          height: "400px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "8px",
                          boxShadow: 2,
                          textAlign: "center",
                          padding: 2,
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#757575",
                            fontWeight: "bold",
                          }}
                        >
                          No Pending Approvals at the moment.
                        </Typography>
                      </Box>
                    )
                )}
              </div>
              <>{!selectedMandap && <ReportContainer tokenKey={tokenKey} />}</>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        className="custom-modal"
        size="xl"
        show={showChanges}
        onHide={() => setShowChanges(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modified item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modifiedData.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Old Value</th>
                  <th>New Value</th>
                </tr>
              </thead>
              <tbody>
                {/* {modifiedData.map((diff, index) => (
                <tr key={index}>
                  <td>{diff.key}</td>
                  <td>{diff.oldValue}</td>
                  <td>{diff.newValue}</td>
                </tr>
              ))} */}
                {modifiedData.map((diff, index) => {
                  const isSpecialCase =
                    diff.oldValue === false && diff.newValue === true;

                  return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: isSpecialCase
                          ? "#dff0d8"
                          : "transparent",
                      }}
                    >
                      <td>{diff.key}</td>
                      <td>
                        {isSpecialCase ? (
                          "Previously False"
                        ) : diff.key === "price_details_incl_price" ? (
                          <table>
                            <thead>
                              <tr>
                                <th>Include</th>
                                <th>Exclude</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(() => {
                                const includes = processInclPrice(
                                  oldMandapData || {}
                                ).filter((item) => item.category === "include");
                                const excludes = processInclPrice(
                                  oldMandapData || {}
                                ).filter((item) => item.category === "exclude");
                                const maxRows = Math.max(
                                  includes.length,
                                  excludes.length
                                );

                                return Array.from({ length: maxRows }).map(
                                  (_, index) => (
                                    <tr key={`old-row-${index}`}>
                                      <td>
                                        {includes[index]
                                          ? `${includes[index]?.name} - Rs ${includes[index]?.price}`
                                          : ""}
                                      </td>
                                      <td>
                                        {excludes[index]
                                          ? `${excludes[index]?.name} - Rs ${includes[index]?.price}`
                                          : ""}
                                      </td>
                                    </tr>
                                  )
                                );
                              })()}
                            </tbody>
                          </table>
                        ) : diff.key === "price_details_images" ? (
                          renderHyperlinks(diff.oldValue) // Render old hyperlinks
                        ) : (
                          diff.oldValue?.toString()
                        )}
                      </td>

                      <td>
                        {isSpecialCase ? (
                          "Now True"
                        ) : diff.key === "price_details_incl_price" ? (
                          <table>
                            <thead>
                              <tr>
                                <th>Include</th>
                                <th>Exclude</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(() => {
                                const includes = processInclPrice(
                                  pendingMandapData || {}
                                ).filter((item) => item.category === "include");
                                const excludes = processInclPrice(
                                  pendingMandapData || {}
                                ).filter((item) => item.category === "exclude");
                                const maxRows = Math.max(
                                  includes.length,
                                  excludes.length
                                );

                                return Array.from({ length: maxRows }).map(
                                  (_, index) => (
                                    <tr key={`new-row-${index}`}>
                                      <td>
                                        {includes[index]
                                          ? `${includes[index].name} - Rs ${includes[index].price}`
                                          : ""}
                                      </td>
                                      <td>
                                        {excludes[index]
                                          ? `${excludes[index].name} - Rs ${excludes[index].price}`
                                          : ""}
                                      </td>
                                    </tr>
                                  )
                                );
                              })()}
                            </tbody>
                          </table>
                        ) : diff.key === "price_details_images" ? (
                          renderHyperlinks(diff.newValue) // Render new hyperlinks
                        ) : (
                          diff.newValue?.toString()
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>No changes detected.</p>
          )}
          {/* {modalData ? (
            <BootstrapTable
              keyField="id"
              data={modalData}
              columns={modalColumns}
            />
          ) : (
            <p>Loading modal data...</p>
          )} */}
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

const MandapamPending = (props) => {
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { levels, fuels, ACTypes, foodTypes } = props?.defaultTypes;

  const [mandap, setMandap] = useState(props.mandap);
  const [readOnly, setReadOnly] = useState(false);

  const [classification, setclassification] = useState(
    props.mandap?.classification_name || null
  );
  const [cookingFuel, setcookingFuel] = useState(
    props.mandap?.cooking_fuel_used || null
  );
  const [hallLevel, setHallLevel] = useState(props.mandap?.hall_level || null);
  const [diningLevel, setDiningLevel] = useState(
    props.mandap?.dining_level || null
  );
  const [ac, setAC] = useState(props.mandap?.ac_type || null);
  const [food, setFood] = useState(props.mandap?.food_type || null);
  const [area, setarea] = useState(props.mandap?.area_name || null);
  const [city, setcity] = useState(props.mandap?.city_name || null);
  const [state, setstate] = useState(props.mandap?.state_name || null);
  const [type, settype] = useState(props.mandap?.mandap_type_name || null);

  console.log(
    "########",
    classification,
    cookingFuel,
    hallLevel,
    diningLevel,
    ac,
    food,
    area,
    city,
    state,
    type
  );
  const {
    classificationList,
    areaList,
    areaData,
    cityList,
    cityData,
    stateList,
    typeList,
  } = props;

  console.log(
    "2222>>>>>>>>>>>>>",
    classificationList,
    areaList,
    areaData,
    cityList,
    cityData,
    stateList,
    typeList
  );

  const [selectDropDownString, setSelectDropDownString] = useState("");
  // const [entryTime, setentryTime] = useState(null);
  // const [exitTime, setexitTime] = useState(null);
  const [vendorName, setvendorName] = useState(null);
  const [vendorList, setvendorList] = useState([]);
  const [isTicked, setIsTicked] = useState(false);

  const [offersList, setOffersList] = useState([{ id: Date.now(), value: "" }]);
  const errors = props.errors;
  const [priceDetails, setPriceDetails] = useState(
    props.mandap?.price_details || {}
  );
  const [includePrice, setIncludePrice] = useState([]);
  const [excludePrice, setExcludePrice] = useState([]);

  const handlePriceChange = (e, field) => {
    setPriceDetails((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleInputMandap = (keyName, value) => {
    setMandap({
      ...mandap,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
    // if(keyName === 'address_1' && value.length >=5) sethasAddr(true)
    // else if(keyName === 'address_1' && value.length <5) sethasAddr(false)
  };
  const handleMandapCheckBox = (keyName, value) => {
    setMandap({
      ...mandap,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
  };
  console.log("mandap", mandap);
  const handleSelect = (keyName, setFn, e) => {
    if (keyName === "area_id") {
      if (e && e.value) {
        let selArea = areaData.filter((obj) => obj.id === e.value);
        setarea(e);
        setcity({ label: selArea[0].city_name, value: selArea[0].city_id });
        setstate({ label: selArea[0].state_name, value: selArea[0].state_id });
        setMandap({
          ...mandap,
          area_id: e.value,
          city_id: selArea[0].city_id,
          state_id: selArea[0].state_id,
        });
        props.save(
          {
            area_id: e.value,
            city_id: selArea[0].city_id,
            state_id: selArea[0].state_id,
          },
          "area_id"
        );
      } else {
        setarea(null);
        setcity(null);
        setstate(null);
        setMandap({
          ...mandap,
          area_id: null,
          city_id: null,
          state_id: null,
        });
        props.save({ area_id: null, city_id: null, state_id: null }, "area_id");
      }
    } else if (keyName === "hall_level") {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
        lift_access_to_hall:
          e && e.value === "ground_level" ? false : mandap.lift_access_to_hall,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
          lift_access_to_hall:
            e && e.value === "ground_level"
              ? false
              : mandap.lift_access_to_hall,
        },
        keyName
      );
    } else if (keyName === "dining_level") {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
        lift_access_to_dining_hall:
          e && e.value === "ground_level"
            ? false
            : mandap.lift_access_to_dining_hall,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
          lift_access_to_dining_hall:
            e && e.value === "ground_level"
              ? false
              : mandap.lift_access_to_dining_hall,
        },
        keyName
      );
    } else {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
        },
        keyName
      );
    }
  };

  const getCoordinates = (data) => {
    setMandap({
      ...mandap,
      latitude: data.lat,
      longitude: data.lng,
    });
    props.saveMap(data);
  };

  console.log(
    'props.mandap.offers_list !==""&&props.mandap.offers_list !==undefined&& props.mandap.offers_list !==null',
    props.mandap.offers_list !== "" &&
      props.mandap.offers_list !== undefined &&
      props.mandap.offers_list !== null
  );
  useEffect(() => {
    if (
      props.mandap.offers_list !== "" &&
      props.mandap.offers_list !== undefined &&
      props.mandap.offers_list !== null
    ) {
      console.log("entered offerlist");
      const offerVal = JSON.parse(props.mandap.offers_list);
      console.log("offerVal", offerVal);
      setOffersList(offerVal);
    }
  }, [props.mandap.offers_list]);

  const getLocation = () => {
    // sethasAddr(true);
    // setLocData({ address_1: mandap.address_1 });
  };
  const handleInputChange = (event, newInputValue) => {
    setSelectDropDownString(newInputValue);
    setvendorName(newInputValue);
    // setVendor(newInputValue);
  };

  useEffect(() => {
    if (props?.mandap?.vendor_id !== undefined) {
      axios
        .get(
          config.service +
            `/masters/base/vendor/?&id=${props?.mandap?.vendor_id}`
        )
        .then((res) => {
          setvendorName(res?.data?.results[0]?.vendor_name);
        });
    }
  }, [props]);

  // useDebounce hook for dropDown menu
  const debouncedValue = useDebounce(selectDropDownString, 500);
  const search = useCallback(async () => {
    get(
      config.service + `/masters/base/vendor/?&search=${debouncedValue}`,
      tokenKey
    ).then((res) => {
      let vendors = [];
      res?.data?.results?.map((obj) => {
        vendors.push({ value: obj.id, label: obj.vendor_name });
      });
      setvendorList(vendors);
    });
  }, [debouncedValue]);
  const handleAddOffer = () => {
    console.log("offersList", offersList);
    setOffersList([...offersList, { id: Date.now(), value: "" }]);
  };
  console.log("offersList", offersList);
  const handleOfferChange = (e, id) => {
    console.log("id", id);
    console.log("e", e.target.value);
    const itemValue = offersList.map((i) => {
      if (i.id === id) {
        return { id: i.id, value: e.target.value };
      }
      return i;
    });
    setOffersList(itemValue);
    setMandap({
      ...mandap,
      offers_list: JSON.stringify(itemValue),
    });
    props.save(
      {
        offers_list: JSON.stringify(itemValue),
      },
      "offers_list"
    );
    console.log("itemValue", itemValue);
  };
  const handleDeleteOffer = (item, index) => {
    console.log("item", item);
    console.log("index", index);
    if (index === 0 && offersList.length === 1) {
      console.log(
        "index===0 && offersList.length===1 ",
        index === 0 && offersList.length === 1
      );
      setOffersList([{ id: "", value: "" }]);
      setMandap({
        ...mandap,
        offers_list: null,
      });
      props.save(
        {
          offers_list: null,
        },
        "offers_list"
      );
    } else {
      const itemValue = offersList.filter((i) => i.id !== item.id);
      setOffersList(itemValue);
      setMandap({
        ...mandap,
        offers_list: itemValue,
      });
      props.save(
        {
          offers_list: JSON.stringify(itemValue),
        },
        "offers_list"
      );
    }
  };

  useEffect(() => {
    search();
  }, [debouncedValue, search]);
  const handleFinalOffer = () => {
    setIsTicked(true);
    console.log("offerList", offersList);
    setMandap({
      ...mandap,
      offers_list: JSON.stringify(offersList),
    });
    props.save(
      {
        offers_list: JSON.stringify(offersList),
      },
      "offers_list"
    );
  };
  console.log("mandap", mandap);
  console.log("props", props.save);

  const formatItems = (items, prices = []) => {
    return items.map((item) => {
      const priceObj = prices.find((price) => price.id === item.id);
      const priceText = priceObj ? `₹${priceObj.price}` : "No Price";
      const description = item.description
        ? item.description
        : "No description";

      return `${item.name} (${description}) - ${priceText}`;
    });
  };

  useEffect(() => {
    // Parse and format include and exclude price data
    const formattedIncludePrice = formatItems(
      priceDetails.include_list || [],
      priceDetails.incl_price ? JSON.parse(priceDetails.incl_price) : []
    );
    const formattedExcludePrice = formatItems(
      priceDetails.exclude_list || [],
      priceDetails.incl_price ? JSON.parse(priceDetails.incl_price) : []
    );

    // Set state with formatted data
    setIncludePrice(formattedIncludePrice);
    setExcludePrice(formattedExcludePrice);
  }, [priceDetails]);

  return (
    <Row>
      <Col md={12}>
        {/* <Loader loading={false} overlay={props.overlay}> */}
        <Loader loading={props.loading} overlay={props.overlay}>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Contact Person Details</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "contact_person_name"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Name
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="text"
                      name="contact_person_name"
                      value={mandap.contact_person_name}
                      onChange={(e) =>
                        handleInputMandap("contact_person_name", e.target.value)
                      }
                      isInvalid={!!errors.contact_person_name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("mobile_no")
                          ? "red"
                          : "black",
                      }}
                    >
                      Mobile
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="mobile_no"
                      value={mandap.mobile_no}
                      onChange={(e) =>
                        handleInputMandap("mobile_no", e.target.value)
                      }
                      isInvalid={!!errors.mobile_no}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("lane_line_number")
                          ? "red"
                          : "black",
                      }}
                    >
                      Alternate Number
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="lane_line_number"
                      value={mandap.lane_line_number}
                      onChange={(e) =>
                        handleInputMandap("lane_line_number", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("email")
                          ? "red"
                          : "black",
                      }}
                    >
                      Email
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="email"
                      name="email"
                      value={mandap.email}
                      onChange={(e) =>
                        handleInputMandap("email", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Mandap Features</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("vendor_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      Vendor
                    </label>
                  </Col>
                  <Col md={7}>
                    <Autocomplete
                      className={`selectBox ${
                        !!errors.vendor_id ? "inValidField" : ""
                      }`}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      value={vendorName}
                      onChange={(event, newValue) => {
                        props.save({
                          vendor_id: newValue?.value || null,
                        });
                      }}
                      inputValue={vendorName}
                      onInputChange={(event, newInputValue) => {
                        handleInputChange(event, newInputValue);
                        setMandap({
                          ...mandap,
                          vendor_id: newInputValue || null,
                        });
                      }}
                      id="controllable-states-demo"
                      options={vendorList}
                      renderInput={(params) => <TextField {...params} />}
                      isClearable
                    />
                    {errors?.vendor_id ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors?.vendor_id}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("mandap_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      Mandap Name
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="text"
                      name="mandap_name"
                      value={mandap.mandap_name}
                      onChange={(e) =>
                        handleInputMandap("mandap_name", e.target.value)
                      }
                      isInvalid={!!errors.mandap_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mandap_name}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("mandap_type_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      Mandap Type
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.mandap_type_id ? "inValidField" : ""
                      }`}
                      isDisabled={readOnly}
                      value={type}
                      onChange={(e) =>
                        handleSelect("mandap_type_id", settype, e)
                      }
                      options={typeList}
                      isClearable
                    />
                    {errors.mandap_type_id ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mandap_type_id}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "classification_name"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Mandap Classification
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.mandap_classification_id ? "inValidField" : ""
                      }`}
                      isDisabled={readOnly}
                      value={classification}
                      onChange={(e) =>
                        handleSelect(
                          "mandap_classification_id",
                          setclassification,
                          e
                        )
                      }
                      options={classificationList}
                      isClearable
                    />
                    {errors.mandap_classification_id ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mandap_classification_id}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {/* <Row>
                    <Col md={4}>
                        <label>Address 2</label>
                    </Col>
                    <Col md={7}>
                    <Form.Control disabled={readOnly} as='textarea' rows="4" cols="50" name="address_2" value={mandap.address_2} onChange={e=> handleInputMandap('address_2',e.target.value)} />
                    </Col>
                </Row> */}
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("guest")
                          ? "red"
                          : "black",
                      }}
                    >
                      Floating Capacity
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="guest"
                      value={mandap.guest}
                      onChange={(e) =>
                        handleInputMandap("guest", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("area_sqft")
                          ? "red"
                          : "black",
                      }}
                    >
                      Area (sqft)
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="area_sqft"
                      value={mandap.area_sqft}
                      onChange={(e) =>
                        handleInputMandap("area_sqft", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("address_1")
                          ? "red"
                          : "black",
                      }}
                    >
                      Address
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="address_1"
                      value={mandap.address_1}
                      onChange={(e) =>
                        handleInputMandap("address_1", e.target.value)
                      }
                      isInvalid={!!errors.address_1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address_1}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("pincode")
                          ? "red"
                          : "black",
                      }}
                    >
                      Pincode
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="pincode"
                      value={mandap.pincode}
                      onChange={(e) =>
                        handleInputMandap("pincode", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Map</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4} className="pad-t-20">
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("latitude")
                          ? "red"
                          : "black",
                      }}
                    >
                      Latitude
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="latitude"
                      value={mandap.latitude}
                      onChange={(e) =>
                        handleInputMandap("latitude", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={4} className="pad-t-20">
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("longitude")
                          ? "red"
                          : "black",
                      }}
                    >
                      Longitude
                    </Form.Label>
                  </Col>
                  <Col md={7} className="pad-t-20">
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="longitude"
                      value={mandap.longitude}
                      onChange={(e) =>
                        handleInputMandap("longitude", e.target.value)
                      }
                    />
                  </Col>
                  {/* {props.mandap.latitude !==null && props.mandap.longitude !==null && */}
                  <>
                    <Col md={4} className="pad-t-20">
                      <label>
                        Location{" "}
                        <HiRefresh
                          size="24"
                          className="locIcon"
                          title="Fetch location from address"
                          onClick={() => getLocation()}
                        />
                      </label>
                    </Col>
                    <Col md={7} className="pad-t-20">
                      <EmbedMap
                        latitude={props.mandap.latitude}
                        longitude={props.mandap.longitude}
                      />
                    </Col>
                  </>
                  {/* } */}
                </Row>
                <Row className="pad-t-20">
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("land_mark")
                          ? "red"
                          : "black",
                      }}
                    >
                      Landmark
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="land_mark"
                      value={mandap.land_mark}
                      onChange={(e) =>
                        handleInputMandap("land_mark", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("area_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      Area
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={area}
                      onChange={(e) => handleSelect("area_id", setarea, e)}
                      options={areaList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("city_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      City
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled
                      value={city}
                      onChange={(e) => handleSelect("city_id", setcity, e)}
                      options={cityList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("state_name")
                          ? "red"
                          : "black",
                      }}
                    >
                      State
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled
                      value={state}
                      onChange={(e) => handleSelect("state_id", setstate, e)}
                      options={stateList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("on_main_road")
                          ? "red"
                          : "black",
                      }}
                    >
                      On Main Road
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="on_main_road"
                      value={mandap.on_main_road}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("residential_area")
                          ? "red"
                          : "black",
                      }}
                    >
                      Residential Area
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="residential_area"
                      value={mandap.residential_area}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "nearest_Railway_Station"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Nearest Railway Station
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="nearest_Railway_Station"
                      value={mandap.nearest_Railway_Station}
                      onChange={(e) =>
                        handleInputMandap(
                          "nearest_Railway_Station",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "distance_from_railway_station"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Distance from Railway Station
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="distance_from_railway_station"
                      value={mandap.distance_from_railway_station}
                      onChange={(e) =>
                        handleInputMandap(
                          "distance_from_railway_station",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("nearest_bus_stand")
                          ? "red"
                          : "black",
                      }}
                    >
                      Nearest Bus Stand
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="nearest_bus_stand"
                      value={mandap.nearest_bus_stand}
                      onChange={(e) =>
                        handleInputMandap("nearest_bus_stand", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "distance_from_bus_stand"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Distance from Bus Stand
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="distance_from_bus_stand"
                      value={mandap.distance_from_bus_stand}
                      onChange={(e) =>
                        handleInputMandap(
                          "distance_from_bus_stand",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Hall</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("hall_sq_ft")
                          ? "red"
                          : "black",
                      }}
                    >
                      Hall (sqft)
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="hall_sq_ft"
                      value={mandap.hall_sq_ft}
                      onChange={(e) =>
                        handleInputMandap("hall_sq_ft", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("hall_height")
                          ? "red"
                          : "black",
                      }}
                    >
                      Hall Height (ft){" "}
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="hall_height"
                      value={mandap.hall_height}
                      onChange={(e) =>
                        handleInputMandap("hall_height", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("hall_level")
                          ? "red"
                          : "black",
                      }}
                    >
                      Hall Level
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={hallLevel}
                      onChange={(e) =>
                        handleSelect("hall_level", setHallLevel, e)
                      }
                      options={levels}
                      isClearable
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("pillarless_hall")
                          ? "red"
                          : "black",
                      }}
                    >
                      Pillarless Hall
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="pillarless_hall"
                      value={mandap.pillarless_hall}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("seating_capacity")
                          ? "red"
                          : "black",
                      }}
                    >
                      Seating Capacity
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="seating_capacity"
                      value={mandap.seating_capacity}
                      onChange={(e) =>
                        handleInputMandap("seating_capacity", e.target.value)
                      }
                      isInvalid={!!errors.seating_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.seating_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "lift_access_to_hall"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Lift Access
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={
                        (hallLevel && hallLevel.value === "ground_level") ||
                        readOnly
                      }
                      handleChange={handleMandapCheckBox}
                      name="lift_access_to_hall"
                      value={mandap.lift_access_to_hall}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("hall_ac")
                          ? "red"
                          : "black",
                      }}
                    >
                      AC Hall
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="hall_ac"
                      value={mandap.hall_ac}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Stage</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("stage_available")
                          ? "red"
                          : "black",
                      }}
                    >
                      Stage Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="stage_available"
                      value={mandap.stage_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "primary_stage_dim_ft"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Primary Stage Dimension (ft)
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="primary_stage_dim_ft"
                      value={mandap.primary_stage_dim_ft}
                      onChange={(e) =>
                        handleInputMandap(
                          "primary_stage_dim_ft",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "homam_havan_fireritual"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Homam/Haven Fire Rituals
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="homam_havan_fireritual"
                      value={mandap.homam_havan_fireritual}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "secondary_stage_available"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Secondary Stage Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="secondary_stage_available"
                      value={mandap.secondary_stage_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "secondary_stage_dim_ft"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Secondary Stage Dimension (ft)
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="secondary_stage_dim_ft"
                      value={mandap.secondary_stage_dim_ft}
                      onChange={(e) =>
                        handleInputMandap(
                          "secondary_stage_dim_ft",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("handicap_ramp")
                          ? "red"
                          : "black",
                      }}
                    >
                      Handicap Ramp
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="handicap_ramp"
                      value={mandap.handicap_ramp}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>

          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Dining</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("dining_capacity")
                          ? "red"
                          : "black",
                      }}
                    >
                      Dining Capacity
                    </Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="dining_capacity"
                      value={mandap.dining_capacity}
                      onChange={(e) =>
                        handleInputMandap("dining_capacity", e.target.value)
                      }
                      isInvalid={!!errors.dining_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dining_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("dining_level")
                          ? "red"
                          : "black",
                      }}
                    >
                      Dining Level
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={diningLevel}
                      onChange={(e) =>
                        handleSelect("dining_level", setDiningLevel, e)
                      }
                      options={levels}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "lift_access_to_dining_hall"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Lift Access to Dining Hall
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={
                        (diningLevel && diningLevel.value === "ground_level") ||
                        readOnly
                      }
                      handleChange={handleMandapCheckBox}
                      name="lift_access_to_dining_hall"
                      value={mandap.lift_access_to_dining_hall}
                    />{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("dining_ac")
                          ? "red"
                          : "black",
                      }}
                    >
                      AC Dining
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="dining_ac"
                      value={mandap.dining_ac}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("catering")
                          ? "red"
                          : "black",
                      }}
                    >
                      Catering
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="catering"
                      value={mandap.catering}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "outdoor_catering_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Outdoor Catering Allowed
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outdoor_catering_allowed"
                      value={mandap.outdoor_catering_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "non_veg_cooking_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Non-veg Cooking allowed
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="non_veg_cooking_allowed"
                      value={mandap.non_veg_cooking_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "non_veg_service_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Non-veg Service Allowed
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="non_veg_service_allowed"
                      value={mandap.non_veg_service_allowed}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("outdoor_buffet")
                          ? "red"
                          : "black",
                      }}
                    >
                      Outdoor Buffet Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outdoor_buffet"
                      value={mandap.outdoor_buffet}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "cooking_fuel_provided"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Cooking Fuel Provided
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="cooking_fuel_provided"
                      value={mandap.cooking_fuel_provided}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("cooking_fuel_used")
                          ? "red"
                          : "black",
                      }}
                    >
                      Cooking Fuel Used
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={cookingFuel}
                      onChange={(e) =>
                        handleSelect("cooking_fuel_used", setcookingFuel, e)
                      }
                      options={fuels}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("piped_gas")
                          ? "red"
                          : "black",
                      }}
                    >
                      Piped Gas
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="piped_gas"
                      value={mandap.piped_gas}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("food_type")
                          ? "red"
                          : "black",
                      }}
                    >
                      Food Type
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.food_type ? "inValidField" : ""
                      }`}
                      value={food}
                      isDisabled={readOnly}
                      onChange={(e) => handleSelect("food_type", setFood, e)}
                      options={foodTypes}
                      isClearable
                    />
                    {errors.food_type ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.food_type}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "cooking_utensils_available"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Cooking Utensils Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="cooking_utensils_available"
                      value={mandap.cooking_utensils_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "buffet_crockery_available"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Buffet Crockery Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="buffet_crockery_available"
                      value={mandap.buffet_crockery_available}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Parking</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "car_parking_capacity"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Car Parking Capacity
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="car_parking_capacity"
                      value={mandap.car_parking_capacity}
                      onChange={(e) =>
                        handleInputMandap(
                          "car_parking_capacity",
                          e.target.value
                        )
                      }
                      isInvalid={!!errors.car_parking_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.car_parking_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "bike_parking_capacity"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Bike Parking Capacity
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="bike_parking_capacity"
                      value={mandap.bike_parking_capacity}
                      onChange={(e) =>
                        handleInputMandap(
                          "bike_parking_capacity",
                          e.target.value
                        )
                      }
                      isInvalid={!!errors.bike_parking_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bike_parking_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("valet_parking")
                          ? "red"
                          : "black",
                      }}
                    >
                      Valet Parking
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="valet_parking"
                      value={mandap.valet_parking}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Rooms</legend>
            <Row>
              <Col md={6}>
                {/* <Row>
                    <Col md={4}>
                        <label>No. of Rooms</label>
                    </Col>
                    <Col md={7}>
                    <Form.Control disabled={readOnly} type='number' name="no_of_rooms" value={mandap.no_of_rooms} onChange={e=> handleInputMandap('no_of_rooms',e.target.value)} isInvalid={!! errors.no_of_rooms} />
                    <Form.Control.Feedback type='invalid'>
                        { errors.no_of_rooms }
                    </Form.Control.Feedback>
                    </Col>
                </Row> */}
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("no_of_ac_rooms")
                          ? "red"
                          : "black",
                      }}
                    >
                      No. of AC Rooms
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="no_of_ac_rooms"
                      value={mandap.no_of_ac_rooms}
                      onChange={(e) =>
                        handleInputMandap("no_of_ac_rooms", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("no_of_nonac_rooms")
                          ? "red"
                          : "black",
                      }}
                    >
                      No. of Non-AC Rooms
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="no_of_nonac_rooms"
                      value={mandap.no_of_nonac_rooms}
                      onChange={(e) =>
                        handleInputMandap("no_of_nonac_rooms", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {/* <Row>
                    <Col md={4}>
                        <label>Groom Rooms</label>
                    </Col>
                    <Col md={7}>
                    <CustomSwitch disabled={readOnly} handleChange={handleMandapCheckBox} name = 'grooms_room' value={mandap.grooms_room} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label>Bride Rooms</label>
                    </Col>
                    <Col md={7}>
                    <CustomSwitch disabled={readOnly} handleChange={handleMandapCheckBox} name = 'bride_room' value={mandap.bride_room} />
                    </Col>
                </Row> */}
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("ac_type")
                          ? "red"
                          : "black",
                      }}
                    >
                      AC Type
                    </label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.ac_type ? "inValidField" : ""
                      }`}
                      value={ac}
                      isDisabled={readOnly}
                      onChange={(e) => handleSelect("ac_type", setAC, e)}
                      options={ACTypes}
                      isClearable
                    />
                    {errors.ac_type ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ac_type}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("safe_available")
                          ? "red"
                          : "black",
                      }}
                    >
                      Safe Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="safe_available"
                      value={mandap.safe_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "rooms_terms_and_condition"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Rooms - Terms & Conditions
                    </label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="rooms_terms_and_condition"
                      value={mandap.rooms_terms_and_condition}
                      onChange={(e) =>
                        handleInputMandap(
                          "rooms_terms_and_condition",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Offers</legend>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={2}>
                    <Form.Label
                      style={{
                        color: props?.modifiedKeys.includes("offersList")
                          ? "red"
                          : "black",
                      }}
                    >
                      Offer List
                    </Form.Label>
                  </Col>
                  <Col md={10}>
                    {offersList?.map((item, index) => {
                      return (
                        <Row key={index}>
                          <Col md={8}>
                            <Form.Control
                              disabled={readOnly || isTicked}
                              type="text"
                              name=""
                              key={index}
                              value={item.value}
                              onChange={(e) => handleOfferChange(e, item.id)}
                              isInvalid={!!errors.dining_capacity}
                            />
                          </Col>
                          {isTicked === false && (
                            <>
                              <Col md={0.5}>
                                <IconButton
                                  variant="contained"
                                  size="small"
                                  disableElevation
                                  style={{
                                    color: item.value === "" ? "grey" : "green",
                                    fontSize: "25px",
                                  }}
                                  onClick={() => handleAddOffer()}
                                  key={item?.id}
                                  disabled={item.value === ""}
                                >
                                  <IoAddCircleSharp />
                                </IconButton>
                              </Col>
                              <Col md={0.5}>
                                <IconButton
                                  aria-label="delete"
                                  variant="contained"
                                  size="small"
                                  disableElevationindex
                                  key={index}
                                  // disabled={index === 0}
                                  style={{
                                    color: "red",
                                    fontSize: "25px",
                                  }}
                                  onClick={() => handleDeleteOffer(item, index)}
                                >
                                  <MdDelete />
                                </IconButton>
                              </Col>
                            </>
                          )}
                          {item.value !== "" &&
                          offersList.length - 1 === index ? (
                            <Col md={0.5}>
                              <IconButton
                                variant="contained"
                                size="small"
                                disableElevation
                                style={{
                                  color: item.value === "" ? "grey" : "#ee448b",
                                  fontSize: "25px",
                                }}
                                onClick={() => handleFinalOffer()}
                                key={item?.id}
                                disabled={item.value === ""}
                              >
                                <TiTick />
                              </IconButton>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Others</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("wifi")
                          ? "red"
                          : "black",
                      }}
                    >
                      WIFI
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="wifi"
                      value={mandap.wifi}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("common_area_ac")
                          ? "red"
                          : "black",
                      }}
                    >
                      Common Area AC
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="common_area_ac"
                      value={mandap.common_area_ac}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("generator_backup")
                          ? "red"
                          : "black",
                      }}
                    >
                      Backup Generator
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="generator_backup"
                      value={mandap.generator_backup}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("serving_available")
                          ? "red"
                          : "black",
                      }}
                    >
                      Serving Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="serving_available"
                      value={mandap.serving_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "have_non_muhurtham_price"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Have Pricing for Non-muhurtham dates
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="have_non_muhurtham_price"
                      value={mandap.have_non_muhurtham_price}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "outside_entertainment_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Outside Entertainment Available
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outside_entertainment_allowed"
                      value={mandap.outside_entertainment_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "outside_vendor_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Outside Vendors Allowed
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outside_vendor_allowed"
                      value={mandap.outside_vendor_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("other_facilities")
                          ? "red"
                          : "black",
                      }}
                    >
                      Other Facilities
                    </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="other_facilities"
                      value={mandap.other_facilities}
                      onChange={(e) =>
                        handleInputMandap("other_facilities", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "outside_vendor_allowed"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Other Terms & Conditions
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outside_vendor_allowed"
                      value={mandap.outside_vendor_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("bwg_promise")
                          ? "red"
                          : "black",
                      }}
                    >
                      Bookwedgo Promise
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="bwg_promise"
                      value={mandap.bwg_promise}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("having_offer")
                          ? "red"
                          : "black",
                      }}
                    >
                      Having Offers
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="having_offer"
                      value={mandap.having_offer}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {mandap.having_offer ? (
                  <Row>
                    <Col md={4}>
                      <label
                        style={{
                          color: props?.modifiedKeys.includes("offers")
                            ? "red"
                            : "black",
                        }}
                      >
                        Offers
                      </label>
                    </Col>
                    <Col md={7}>
                      <Form.Control
                        disabled={readOnly}
                        as="textarea"
                        rows="4"
                        cols="50"
                        name="offers"
                        value={mandap.offers}
                        onChange={(e) =>
                          handleInputMandap("offers", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("is_popular")
                          ? "red"
                          : "black",
                      }}
                    >
                      Mark as Popular
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="is_popular"
                      value={mandap.is_popular}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "disable_online_bookings"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Disable online Bookings
                    </label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="disable_online_bookings"
                      value={mandap?.disable_online_bookings}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={2}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes("title_description")
                          ? "red"
                          : "black",
                      }}
                    >
                      Title Description
                    </label>
                  </Col>
                  <Col md={10}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="title_description"
                      placeholder="Description to be displayed in title"
                      value={mandap.title_description}
                      onChange={(e) =>
                        handleInputMandap("title_description", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={2}>
                    <label
                      style={{
                        color: props?.modifiedKeys.includes(
                          "mandap_description"
                        )
                          ? "red"
                          : "black",
                      }}
                    >
                      Description
                    </label>
                  </Col>
                  <Col md={10}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="mandap_description"
                      placeholder="Describe about the mandapam in few words..."
                      value={mandap.mandap_description}
                      onChange={(e) =>
                        handleInputMandap("mandap_description", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          {/* <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Pricing Details</legend>
            <Row>
              <Col md={12}>
                <Row className="mt-3">
                  <Col md={2}>
                    <Form.Label>Price Details</Form.Label>
                  </Col>
                  <Col md={10}>
                    <Row>
                      <Col md={4}>
                        <Form.Label>Full-Day Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.fd_price || ""}
                          onChange={(e) => handlePriceChange(e, "fd_price")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Half-Day Morning Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.hf_am_price || ""}
                          onChange={(e) => handlePriceChange(e, "hf_am_price")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Half-Day Evening Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.hf_pm_price || ""}
                          onChange={(e) => handlePriceChange(e, "hf_pm_price")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Reservation Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.reservation_price || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "reservation_price")
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Price per Veg Meal</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.price_per_veg || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "price_per_veg")
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Price per Non-Veg Meal</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.price_per_nonveg || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "price_per_nonveg")
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Breakfast Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_breakfast_vg || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "pr_breakfast_vg")
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Breakfast Non-Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_breakfast_nvg || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "pr_breakfast_nvg")
                          }
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Lunch Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_lunch_vg || ""}
                          onChange={(e) => handlePriceChange(e, "pr_lunch_vg")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Lunch Non-Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_lunch_nvg || ""}
                          onChange={(e) => handlePriceChange(e, "pr_lunch_nvg")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Dinner Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_dinner_vg || ""}
                          onChange={(e) => handlePriceChange(e, "pr_dinner_vg")}
                        />
                      </Col>
                      <Col md={4}>
                        <Form.Label>Dinner Non-Veg Price</Form.Label>
                        <Form.Control
                          type="number"
                          value={priceDetails.pr_dinner_nvg || ""}
                          onChange={(e) =>
                            handlePriceChange(e, "pr_dinner_nvg")
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset> */}
          <fieldset className="border p-2 fieldsetCls">
            <legend className="w-auto">Pricing Details</legend>
            <Row>
              <Col md={12}>
                <Row className="mt-3">
                  <Col md={2}>
                    <Form.Label>Price Details</Form.Label>
                  </Col>
                  <Col md={10}>
                    <Row>
                      {/* Existing Price Fields */}
                      {[
                        { label: "Full-Day Price", key: "fd_price" },
                        { label: "Half-Day Morning Price", key: "hf_am_price" },
                        { label: "Half-Day Evening Price", key: "hf_pm_price" },
                        {
                          label: "Reservation Price",
                          key: "reservation_price",
                        },
                        { label: "Price per Veg Meal", key: "price_per_veg" },
                        {
                          label: "Price per Non-Veg Meal",
                          key: "price_per_nonveg",
                        },
                        {
                          label: "Breakfast Veg Price",
                          key: "pr_breakfast_vg",
                        },
                        {
                          label: "Breakfast Non-Veg Price",
                          key: "pr_breakfast_nvg",
                        },
                        { label: "Lunch Veg Price", key: "pr_lunch_vg" },
                        { label: "Lunch Non-Veg Price", key: "pr_lunch_nvg" },
                        { label: "Dinner Veg Price", key: "pr_dinner_vg" },
                        { label: "Dinner Non-Veg Price", key: "pr_dinner_nvg" },
                      ].map((field, index) => (
                        <Col md={4} key={index}>
                          <Form.Label>{field.label}</Form.Label>
                          <Form.Control
                            type="number"
                            value={priceDetails[field.key] || ""}
                            onChange={(e) => handlePriceChange(e, field.key)}
                          />
                        </Col>
                      ))}

                      {/* Images Section */}
                      <Col md={12} className="mt-3">
                        <Form.Label>Images</Form.Label>
                        <div>
                          {priceDetails.images?.map((image, idx) => (
                            <div
                              key={idx}
                              style={{ fontSize: "12px", color: "blue" }}
                            >
                              <img
                                src={image}
                                alt={`Image ${idx + 1}`}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                }}
                              />
                              <a
                                href={image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Image
                              </a>
                            </div>
                          ))}
                        </div>
                      </Col>

                      {/* Include Prices Section */}
                      <Col md={12} className="mt-3">
                        <Form.Label>Include Prices</Form.Label>
                        <ul style={{ paddingLeft: "20px" }}>
                          {includePrice.map((item, idx) => (
                            <li key={idx} style={{ fontSize: "12px" }}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </Col>

                      {/* Excludes Section */}
                      <Col md={12} className="mt-3">
                        <Form.Label>Excludes</Form.Label>
                        <ul style={{ paddingLeft: "20px" }}>
                          {excludePrice.map((item, idx) => (
                            <li key={idx} style={{ fontSize: "12px" }}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
        </Loader>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Col>
    </Row>
  );
};

export default VedorMandapPending;
