import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null; // No pagination if only one page.

  const handlePageClick = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      // Show all pages if total pages <= 5
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show current page, and surrounding pages with ellipses
      pages.push(1);
      if (currentPage > 3) pages.push("...");
      for (
        let i = Math.max(2, currentPage - 1);
        i <= Math.min(totalPages - 1, currentPage + 1);
        i++
      ) {
        pages.push(i);
      }
      if (currentPage < totalPages - 2) pages.push("...");
      pages.push(totalPages);
    }
    return pages;
  };

  return (
    <Pagination>
      {/* First Button */}
      <Pagination.Item
        disabled={currentPage === 1}
        onClick={() => handlePageClick(1)}
      >
        {"<<"}
      </Pagination.Item>

      {/* Previous Button */}
      <Pagination.Item
        disabled={currentPage === 1}
        onClick={() => handlePageClick(currentPage - 1)}
      >
        {"<"}
      </Pagination.Item>

      {/* Page Numbers */}
      {generatePageNumbers().map((page, idx) =>
        typeof page === "number" ? (
          <Pagination.Item
            key={idx}
            active={page === currentPage}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis key={idx} disabled />
        )
      )}

      {/* Next Button */}
      <Pagination.Item
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(currentPage + 1)}
      >
        {">"}
      </Pagination.Item>

      {/* Last Button */}
      <Pagination.Item
        disabled={currentPage === totalPages}
        onClick={() => handlePageClick(totalPages)}
      >
        {">>"}
      </Pagination.Item>
    </Pagination>
  );
};

export default PaginationComponent;
