import React, { useState, useCallback, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const SearchableDropdown = ({
  label,
  placeholder,
  options,
  value,
  onSearch,
  onSelect,
  isLoading,
  disabled
}) => {
  const [searchQuery, setSearchQuery] = useState(value?.name || ""); // Initialize with current value
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const debouncedSearch = useCallback(
    debounce((query) => {
      onSearch(query); 
    }, 300), 
    [onSearch]
  );

  useEffect(() => {
    if (value) {
      setSearchQuery(value?.name || "");
    }
  }, [value]);
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query); // Debounced function call
    setDropdownOpen(true); // Show dropdown on search
  };

  const handleSelect = (selectedOption) => {
    setSearchQuery(selectedOption.name); // Update the input value
    onSelect(selectedOption); // Pass selection back to parent
    setDropdownOpen(false); // Close dropdown
  };

  return (
    <Form.Group className="w-100">
      <Form.Label>{label}</Form.Label>
      <div style={{ position: "relative" }}>
        <Form.Control
          type="text"
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setDropdownOpen(true)} // Open dropdown
          onBlur={() => setTimeout(() => setDropdownOpen(false), 200)} // Delay dropdown close
          required
          disabled={disabled}
        />
        {dropdownOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              width: "100%",
              maxHeight: "150px",
              overflowY: "auto",
            }}
          >
            {isLoading ? (
              <div className="text-center p-2">
                <Spinner animation="border" size="sm" /> Loading...
              </div>
            ) : options?.length > 0 ? (
              options?.map((option) => (
                <div
                  key={option.id}
                  onClick={() => handleSelect(option)}
                  style={{ padding: "8px 12px", cursor: "pointer" }}
                  onMouseDown={(e) => e.preventDefault()} // Prevent input blur
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className="text-center p-2">No options found</div>
            )}
          </div>
        )}
      </div>
    </Form.Group>
  );
};

export default SearchableDropdown;
