import React, { useState, useCallback } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Accordion,
  Badge,
  Alert,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { get } from "axios";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import config from "../../../../config";

const INITIAL_FILTERS = {
  from_date: "",
  to_date: "",
  is_approved: false,
  is_rejected: false,
};

const formatRelativeDate = (dateString) => {
  if (!dateString) return "N/A";
  const cleanedDateString = dateString.slice(0, 19);
  const date = new Date(
    cleanedDateString.replace(
      /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/,
      "$3-$2-$1T$4:$5:$6"
    )
  );

  if (isNaN(date)) return "Invalid Date";

  const now = new Date();
  const timeDifference = now - date;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (weeks === 0) {
    if (days === 0) {
      if (hours === 0) {
        if (minutes === 0)
          return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
      }
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    }
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }

  return date.toLocaleString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

const findChanges = (original, pending, parentKey = "") => {
  if (!original || !pending) return [];

  const ignoredFields = [
    "created_on",
    "updated_on",
    "id",
    "created_by_id",
    "updated_by_id",
  ];
  
  const changes = [];

  const checkChanges = (orig, pend, path = "") => {
    Object.keys(pend).forEach((key) => {
      const fullKey = path ? `${path}.${key}` : key;
      if (ignoredFields.includes(key)) return;

      if (
        typeof pend[key] === "object" &&
        pend[key] !== null &&
        orig[key] !== null &&
        !Array.isArray(pend[key])
      ) {
        checkChanges(orig[key] || {}, pend[key], fullKey);
      } else if (JSON.stringify(orig[key]) !== JSON.stringify(pend[key])) {
        changes.push({
          field: fullKey,
          oldValue: orig[key],
          newValue: pend[key],
        });
      }
    });
  };

  checkChanges(original, pending);

  return changes;
};

const ChangeAccordion = ({ change, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggles the icon state
  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <Accordion.Item eventKey={index.toString()} className="border-0">
      <Accordion.Header
      
        onClick={handleToggle} // Trigger icon change on click
        className="py-1 px-2 bg-light rounded d-flex w-100  justify-content-between align-items-center"
        >
        <small
          className="text-muted text-uppercase"
          style={{ fontSize: "0.85rem" }}
        >
          {change.field.replace(/_/g, " ")}
        </small>
        <span >
          {isOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
        </span>
      </Accordion.Header>

      <Accordion.Body className="py-2 px-3">
        <Row className="g-2">
          <Col xs={12}>
            <div className="mb-2">
              <Badge bg="secondary" className="mb-1" style={{ fontSize: "0.7rem" }}>
                Previous
              </Badge>
              <div className="border rounded p-2 bg-light">
                <small className="text-muted" style={{ fontSize: "0.85rem" }}>
                  {typeof change.oldValue === "object"
                    ? JSON.stringify(change.oldValue, null, 2)
                    : String(change.oldValue || "N/A")}
                </small>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div>
              <Badge bg="primary" className="mb-1" style={{ fontSize: "0.7rem" }}>
                New
              </Badge>
              <div className="border rounded p-2 bg-light">
                <small className="text-muted" style={{ fontSize: "0.85rem" }}>
                  {typeof change.newValue === "object"
                    ? JSON.stringify(change.newValue, null, 2)
                    : String(change.newValue || "N/A")}
                </small>
              </div>
            </div>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};


const StatusBadge = ({ item }) => {
  const status = item.pending_changes?.is_approved
    ? ["success", "Approved"]
    : item.pending_changes?.is_rejected
    ? ["danger", "Rejected"]
    : item.pending_changes?.is_edited
    ? ["warning", "Pending Changes"]
    : ["secondary", "No Changes"];

  return <Badge bg={status[0]}>{status[1]}</Badge>;
};

const VendorMandapReport = ({ tokenKey }) => {
  const [showModal, setShowModal] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [error, setError] = useState(null);

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
  
    setFilters((prev) => {
      
      if (name === "is_approved" && checked) {
        return {
          ...prev,
          is_approved: true,
          is_rejected: false, // Uncheck is_rejected
        };
      }
  
      if (name === "is_rejected" && checked) {
        return {
          ...prev,
          is_approved: false, // Uncheck is_approved
          is_rejected: true,
        };
      }
  
      
      return {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }, []);
  

  const fetchReportData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const activeFilters = {
        is_approved: filters.is_approved,
        is_rejected: filters.is_rejected,
        ...(filters.from_date && { from_date: filters.from_date }),
        ...(filters.to_date && { to_date: filters.to_date }),
      };

      const queryString = new URLSearchParams(activeFilters).toString();
      const response = await get(
        `${config.service}/masters/vendor_mandap_pending/report?${queryString}`,
        tokenKey
      );

      setReportData(
        response.data?.map((item) => ({
          ...item,
          processedChanges: findChanges(
            item.vendor_mandap,
            item.pending_changes
          ),
        })) || []
      );
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch report data");
      setReportData([]);
    } finally {
      setLoading(false);
    }
  }, [tokenKey, filters]);

  const exportToCSV = (data) => {
    const headers = [
      "Mandap Name",
      "Field",
      "Old Value",
      "New Value",
      "Status",
      "Modified By",
      "Approved/Rejected By",
      "Last Updated",
    ];
  
    const rows = data.flatMap((item) => {
      return item.processedChanges
        .filter((change) => change.newValue !== undefined && change.newValue !== null) // Skip rows with undefined/null newValue
        .map((change) => [
          item.vendor_mandap?.mandap_name || item.pending_changes?.mandap_name || "N/A",
          change.field,
          typeof change.oldValue === "object"
            ? JSON.stringify(change.oldValue)
            : change.oldValue,
          typeof change.newValue === "object"
            ? JSON.stringify(change.newValue)
            : change.newValue,
          item.pending_changes?.is_approved
            ? "Approved"
            : item.pending_changes?.is_rejected
            ? "Rejected"
            : "Pending",
          item.pending_changes?.created_by_username || "N/A",
          item.pending_changes?.updated_by_username || "N/A",
          item.pending_changes?.updated_on || "N/A",
        ]);
    });
  
    const csvContent = [headers, ...rows]
      .map((row) =>
        row
          .map((value) => `"${String(value).replace(/"/g, '""')}"`) // Escape double quotes
          .join(",")
      )
      .join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `VendorMandapReport_${new Date().toISOString()}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
    

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        View Pending Report
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Vendor Mandap Approval Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}

          <Form className="mb-3">
            <Row className="g-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="from_date"
                    value={filters.from_date}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="to_date"
                    value={filters.to_date}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Form.Check
                  inline
                  type="checkbox"
                  id="is_approved"
                  label="Approved"
                  name="is_approved"
                  checked={filters.is_approved}
                  onChange={handleInputChange}
                />
                <Form.Check
                  inline
                  type="checkbox"
                  id="is_rejected"
                  label="Rejected"
                  name="is_rejected"
                  checked={filters.is_rejected}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Button
                  variant="primary"
                  onClick={fetchReportData}
                  className="me-2"
                >
                  Search
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setFilters(INITIAL_FILTERS)}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>

          {loading ? (
            <div className="text-center my-3">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : reportData.length > 0 ? (
            <Table responsive bordered hover>
              <thead>
                <tr>
                  <th>Mandap Name</th>
                  <th>Changes</th>
                  <th>Status</th>
                  <th>Modified By</th>
                  <th>Approved/Rejected By</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.vendor_mandap?.mandap_name ||
                        item.pending_changes?.mandap_name ||
                        "N/A"}
                    </td>
                    <td>
                      {item.processedChanges?.length ? (
                        <Accordion>
                          {item.processedChanges.map((change, idx) => (
                            <ChangeAccordion
                              key={idx}
                              change={change}
                              index={idx}
                            />
                          ))}
                        </Accordion>
                      ) : (
                        <Alert variant="info" className="mb-0 py-2">
                          No changes detected
                        </Alert>
                      )}
                    </td>
                    <td>
                      <StatusBadge item={item} />
                    </td>
                    <td>
                      {item.pending_changes?.created_by_username || "N/A"}
                    </td>
                    <td>
                      {item.pending_changes?.updated_by_username || "N/A"}
                    </td>
                    <td>
                      {formatRelativeDate(item.pending_changes?.updated_on)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Alert variant="info" className="text-center">
              No report data available for the selected filters.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => exportToCSV(reportData)}
          >
            Export CSV
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorMandapReport;
