import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { FaUserTie, FaTable, FaListUl, FaEdit } from "react-icons/fa";
import { HiPencilAlt, HiOutlineTrash } from "react-icons/hi";
import Search from "../../Helper/CustomSearch";
import axios, { get } from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import Loader from "../../Helper/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const icon = `${process.env.PUBLIC_URL}/img.png`;

const ExecutiveScreen = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.history.location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [tableData, settableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setloading] = useState(true);
  const [view, setview] = useState("list");

  let roleMap = {
    admin: "Admin",
    agent: "Agent",
    vendor: "Vendor",
    customer: "Customer",
    photo_grapher: "Photographer",
  };

  let breadcrumbData = [
    {
      title: "Executives",
      href: "#",
    },
  ];

  const setLoader = (val) => {
    setloading(val);
  };
  const deleteRow = (row) => {
    setLoader(true);
    axios
      .delete(config.service + "/accounts/user/list/" + row.id, tokenKey)
      .then((resp) => {
        get(config.service + "/accounts/user/list/", tokenKey).then((res) => {
          settableData(res.data);
          setLoader(false);
        });
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        setLoader(false);
        // toast.error('Deletion Failed', toastAttr);
      });
  };

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/ExecutiveForm",
        state: {
          id: row.id,
          // vendor_id : propsState.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/ExecutiveForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };

  const cols = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: () => {
        return { width: "120px" };
      },
      formatter: (cell, row) => {
        return <div>{row.name}</div>;
      },
    },
    {
      dataField: "email",
      text: "Email Address",
      headerStyle: () => {
        return { width: "120px" };
      },
      formatter: (cell, row) => {
        return <div>{row.email}</div>;
      },
    },

    {
      dataField: "region",
      text: "Region",
      headerStyle: () => {
        return { width: "120px" };
      },
      // formatter:(cell,row) => {
      //   return(<div>{roleMap[cell]}</div>)
      // }
    },
    {
      dataField: "phone_number",
      text: "Mobile",
      headerStyle: () => {
        return { width: "180px" };
      },
    },
    {
      dataField: "start_date",
      text: "StartDate",
      headerStyle: () => {
        return { width: "180px" };
      },
    },
    {
      dataField: "end_date",
      text: "EndDate",
      headerStyle: () => {
        return { width: "180px" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerStyle: () => {
        return { width: "180px" };
      },
      formatter: (cell) => {
        const bgColor = cell === "active" ? "green" : "red";
        const textColor = "#ffffff";
        return (
          <div style={{ padding: "5px", borderRadius: "4px" }}>
            <span
              style={{
                backgroundColor: bgColor,
                color: textColor,
                padding: "2px 5px",
                borderRadius: "4px",
              }}
            >
              {cell}
            </span>
          </div>
        );
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerStyle: () => {
        return { width: "80px" };
      },
      events: {
        OnClick: (e) => {
          return e.stopPropagation();
        },
      },
      formatter: (cell, row) => {
        return (
          <div className="tableActions">
            <React.Fragment>
              <Link
                to={{
                  pathname: "/executiveForm",
                  state: { id: row.id, editMode: true },
                }}
              >
                <FaEdit title="Edit" className="color-green" />
              </Link>
            </React.Fragment>
          </div>
        );
      },
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: "/executiveForm",
        state: {
          id: row.id,
          editMode: true,
        },
      });
    },
  };

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: tableData.length,
  });

  // const renderOnSearch = searchTerm => {
  //   setLoader(true);
  //   get(config.service+'/accounts/user/list/?&search='+searchTerm,tokenKey).then(res=>{
  //     settableData(res.data);
  //     setLoader(false);
  //   }).catch(err=>{
  //     if(err.response.status === 401) window.location.href = '/';
  //     setLoader(false);
  //     toast.error('Search Failed', toastAttr);
  //   })
  // }

  useEffect(() => {
    get(config.service + "/accounts/kae/", tokenKey)
      .then((res) => {
        settableData(res?.data?.results);
        setLoader(false);
      })
      .catch((err) => {
        if (err.response.status === 401) window.location.href = "/";
        setLoader(false);
      });
  }, []);
  console.log(tableData);
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv mar-b-10">
                <Col md={8}>
                  <Button variant="primary" onClick={(e) => renderForm()}>
                    Create
                  </Button>
                  {/* <Button variant='primary' onClick={e=>setShowModal(true)}>View</Button> */}
                </Col>
                <Col md={4}>
                  {/* <Search callbackFn={e=>renderOnSearch(e)} /> */}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="viewIcons">
                    <span>
                      <FaListUl
                        title="Card View"
                        onClick={() => {
                          setview("list");
                        }}
                      />
                    </span>
                    <span>
                      <FaTable
                        title="List View"
                        onClick={() => {
                          setview("table");
                        }}
                      />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="vendorList">
                <Loader loading={loading}>
                  {view === "list" ? (
                    tableData?.length > 0 ? (
                      tableData?.map((row) => {
                        return (
                          <Col md={4} className="divCont">
                            <Row style={{ width: "100%" }}>
                              <Col md={3}>
                                <div
                                  className="child"
                                  onClick={(e) => renderForm(row)}
                                >
                                  <FaUserTie className="vendorImg" />
                                </div>
                              </Col>
                              <Col md={7}>
                                <div
                                  className="child"
                                  onClick={(e) => renderForm(row)}
                                >
                                  <div className="header"> {row.name} </div>
                                  <div className="d-flex justify-content-start align-items-center">
                                    <span>email : </span>
                                    {row.email}
                                  </div>
                                  <div>
                                    <span>Mobile : </span>
                                    {row.phone_number}
                                  </div>
                                  {/* <div><span>Role :</span> {roleMap[row.user_role]}</div> */}
                                </div>
                              </Col>
                              <Col md={2}>
                                <div className="child">
                                  <HiPencilAlt
                                    className="color-green actionIcon"
                                    onClick={(e) => renderForm(row)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })
                    ) : (
                      <div className="textCenter">No data found</div>
                    )
                  ) : (
                    <BootstrapTable
                      bootstrap4
                      wrapperClasses="table-responsive  pad-20"
                      data={tableData}
                      columns={cols}
                      keyField="id"
                      containerStyle={{ width: "100%" }}
                      hover
                      noDataIndication="No data found"
                      rowEvents={rowEvents}
                      pagination={options}
                    />
                  )}
                </Loader>
                <Col md={6}></Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default ExecutiveScreen;
