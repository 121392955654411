import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../../../config";
import Loader from "../../Helper/Loader";
import empty from "is-empty";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import ConfirmationModel from "../../../shared/ConfirmationModel";
import ErrorHandling from "../../Helper/ErrorHandling";
import Switch from "@mui/material/Switch";
import Papa from "papaparse";
import { fill, set } from "lodash";
const ExecutiveForm = (props) => {
  let history = useHistory();
  let tokenConfig = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let propsState = props.history.location.state;
  console.log("propsState", propsState);

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone_number: "",
    region: "",
    start_date: "2024-01-01",
    // end_date: "",
    assignedMandaps: [],
    status: "active",
    area_name: "",
    assigned_agent: "", // Initialize status
  });
  const [editMode, setEditMode] = useState(
    (propsState && propsState.editMode) || false
  );
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  // const [handleErr, sethandleErr] = useState(false);
  const [errData, seterrData] = useState(null);
  const [filteredMandaps, setFilteredMandaps] = useState([]);
  const [mandapList, setMandapList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  // const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedCity, setSelectedCity] = useState(""); // State for selected agent ID
  const [selectedArea, setSelectedArea] = useState("");
  const [openNested, setOpenNested] = useState(false);
  // const [selectedAgent, setSelectedAgent] = useState(""); // State for selected agent ID
  const [agentDetails, setAgentDetails] = useState({
    name: "",
    mobile: "",
    email: "",
  }); // State for agent details
  // const [loading, setLoading] = useState(true);
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  const [tab, setTab] = useState({
    details: true,
  });
  const [tabData, setTabData] = useState([
    {
      name: "Executive Form",
      id: "details",
      show: true,
    },
  ]);

  let breadcrumbData = [
    {
      title: "Executives",
      href: "/executives",
    },
    {
      title: editMode ? "View" : "Create",
      href: "#",
    },
  ];
  const setLoader = (val) => {
    setloading(val);
    setoverlay(val);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return regex.test(email);
  };
  useEffect(() => {
    axios.get(config.service + "/masters/city/").then((res) => {
      let city = [{ label: "Select City", value: "" }];
      for (let i in res.data)
        city.push({ label: res.data[i].name, value: res.data[i].name });

      setCityList(city);
      setloading(false);
    });

    // axios.get(config.service + "/masters/mandap/").then((res) => {
    //   const mandaps = res.data.map((item) => ({
    //     value: item.id,
    //     label: item.name,
    //   }));
    //   setMandapOptions(mandaps);
    // });
  }, []);

  const handleInput = (keyName, value) => {
    setForm({
      ...form,
      [keyName]: value,
    });
    if (keyName === "region") {
      handleCityChange(value);
    }
    if (keyName && !!errors[keyName])
      setErrors({
        ...errors,
        [keyName]: null,
      });
  };

  const renderListView = () => {
    history.push("/executives");
  };

  const findErrors = () => {
    const { name, phone_number, email, region, start_date, end_date } = form;
    const newErrors = {};
    if (empty(name)) newErrors.name = "This is a required field";
    if (empty(phone_number))
      newErrors.phone_number = "This is a required field";
    if (empty(email)) {
      newErrors.email = "This is a required field";
    } else if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address";
    }
    // if (empty(region)) newErrors.region = "This is a required field";
    // if (empty(start_date)) newErrors.start_date = "This is a required field";
    // if (empty(end_date)) newErrors.end_date = "This is a required field";
    return newErrors;
  };

  // const saveForm = () => {
  //   const newErrors = findErrors();
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     toast.error("Enter all required details to proceed", toastAttr);
  //   } else {
  //     console.log("Form Data:", form);
  //     // Proceed with form submission (e.g., API call)
  //   }
  // };

  //   const handleCityChange = (city) => {
  //     setSelectedCity(city);
  //     if(city){
  //       axios.get(config.service + "/masters/area/list/?&search=" + selectedCity, tokenKey).then((res) => {
  //         let city = [{ label: "Select Area", value: "" }];
  //         for (let i in res.data)
  //           city.push({ label: res.data[i].name, value: res.data[i].name });
  //         setAreaList(city);

  // })
  //     }

  //     console.log("Selected city:", city); // Log the selected city
  //     console.log("Original Mandap List:", mandapList); // Log the original list

  //     if (selectedCity) {
  //       const filtered = mandapList.filter(
  //         (mandap) => mandap.area.toLowerCase() === city.toLowerCase() // Ensure case-insensitivity
  //       );

  //       setFilteredMandaps(filtered); // Set the filtered mandaps
  //       console.log("Filtered Mandaps:", filtered); // Log filtered mandaps here

  //       // Log the current form state before updating it
  //       console.log("Current Form State before update:", form);

  //       // Update the form with the filtered mandaps and keep the rest of the form state
  //       setForm({
  //         ...form,
  //         region: city,
  //         assignedMandaps: filtered, // Ensure you're setting filtered mandaps
  //       });

  //       // Log the updated form state after setting it
  //       console.log("Updated Form State:", {
  //         ...form,
  //         assignedMandaps: filtered,
  //       });
  //     } else {
  //       setFilteredMandaps(mandapList); // Reset to original list if no city is selected
  //       console.log("Resetting to all Mandaps:", mandapList); // Log when resetting
  //       console.log("Updated Form State (after reset):", {
  //         ...form,
  //         assignedMandaps: mandapList, // If you want to keep it consistent with the mandapList
  //       });
  //     }
  //   };
  // let cancelToken; 

  const handleCityChange = async (city) => {
    setSelectedCity(city);
    setSelectedArea(""); // Reset area when city changes
    setForm({
      ...form,
      region: city,
    });
    if (city) {
      // Cancel previous request if necessary (optional)
      // if (cancelToken) {
      //   cancelToken.cancel("Canceled due to new city selection.");
      // }
      // cancelToken = axios.CancelToken.source();

      try {
        const res = await axios.get(
          `${config.service}/masters/area/list/?&search=${city}`,
          tokenKey
        );

        // Prepare the area list
        const areas = [{ label: "Select Area", value: "" }];
        res.data.forEach((area) => {
          areas.push({ label: area.name, value: area.value });
        });
        setAreaList(areas); // Set areas for the selected city
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching area list:", error);
        }
      }
    } else {
      setAreaList([]); // Reset areas if no city is selected
      // setFilteredMandaps(mandapList);
    }
  };

  // Function to handle area selection and filter mandaps
  // const handleAreaChange = (area) => {
  //   setSelectedArea(area); // Update selected area

  //   if (area) {
  //     // Filter mandaps based on the selected area
  //     console.log("Selected Area:", area);
  //     const filtered = mandapList.filter(
  //       (mandap) => mandap?.area?.toLowerCase().trim() === area.toLowerCase().trim()
  //     );
  //     setFilteredMandaps(filtered);
  //     console.log("Filtered Mandaps:", filtered); // Log filtered mandaps here
  //   } else {
  //     setFilteredMandaps(mandapList); // Reset to all mandaps if no area selected
  //   }
  // };

  // console.log("Filtered Mandaps:", filteredMandaps);
  //   useEffect(() => {
  //     const fetchAgents = async () => {
  //         // setLoading(true);
  //         try {
  //           const response = await axios.get(config.service + "/accounts/base/agent", tokenConfig);
  //             const agentsData = response.data.data.map(agent => ({
  //                 id: agent.user_id.id,
  //                 name: agent.user_id.first_name,
  //                 mobile: agent.mobile,
  //                 email: agent.email,
  //             }));

  //             const formattedAgents = agentsData.map(agent => ({
  //                 value: agent.id,
  //                 label: `${agent.name} - ${agent.mobile}`,
  //             }));

  //             setAgentList(formattedAgents);
  //         } catch (error) {
  //             console.error("Error fetching agents:", error);
  //         } finally {
  //             // setLoading(false);
  //         }
  //     };

  //     fetchAgents();

  //     // Handle agent details update based on selected agent
  //     if (selectedAgent) {
  //         const selectedAgentDetails = agentList.find(agent => agent.value === selectedAgent);
  //         if (selectedAgentDetails) {
  //             setAgentDetails({
  //                 name: selectedAgentDetails.label.split(' - ')[0],
  //                 mobile: selectedAgentDetails.mobile,
  //                 email: selectedAgentDetails.email,
  //             });
  //         } else {
  //             setAgentDetails({ name: '', mobile: '', email: '' });
  //         }
  //     }
  // }, [selectedAgent]); // Runs when selectedAgent changes
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axios.get(
          config.service + "/accounts/base/agent",
          // 'https://api.bookwedgo.com/api/accounts/base/agent',
          tokenConfig
        );
        const agentsData = response.data.data.map((agent) => ({
          id: agent.id,
          name: agent.user_id.username,
          mobile: agent.mobile,
          email: agent.user_id.email,
        }));

        setAgentList(agentsData); // Set fetched agents into state
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    fetchAgents();
  }, []); // Run once when the component mounts
  const handleAgentSelection = (agentId) => {
    console.log("AgentSelection", agentId, agentList);
    const selectedAgent = agentList.find(
      (agent) => agent.id === Number(agentId)
    );
    console.log(selectedAgent, "selectedAgent");
    if (selectedAgent) {
      // Update form fields with selected agent's details
      setForm({
        ...form,
        assigned_agent: Number(agentId),
        name: selectedAgent.name,
        email: selectedAgent.email,
        phone_number: selectedAgent.mobile,
      });
    }
  };
  // Function to get area name by area_id with case-insensitivity and trimming

  useEffect(() => {
    if (form?.region) {
      // Fetch areas for the initial selected city (form.region)
      handleCityChange(form.region);
    }
  }, [form.region]);
  const getAreaNameById = (areaId) => {
    const area = areaList.find((area) => area?.value === areaId);
    console.log(area, "area");
    return area ? area.label : "Unknown Area";
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     // Parse CSV file
  //     Papa.parse(file, {
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: (results) => {
  //         // Handle parsed data
  //         const mandaps = results.data.map((item) => ({
  //           id: item.id, // Adjust based on your CSV structure
  //           name: item.mandap_name,
  //           location: item.cityname,
  //           area: item.areaname,
  //         }));
  //         console.log(mandaps, "mandaps");
  //         setMandapList(mandaps);
  //         setFileUploaded(true);
  //         handleCityChange(form.region);
  //         // console.log(mandapList, "mandaps");

  //         toast.success("Mandaps added successfully!");
  //       },
  //     });
  //   }
  // };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split('.').pop().toLowerCase();
  
      if (fileType === 'csv') {
        // Parse CSV file
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const newMandaps = results.data.map((item) => ({
              id: item.id, // Adjust based on your CSV structure
              name: item.mandap_name,
              location: item.cityname,
              area: item.areaname,
            }));
  
            updateMandapList(newMandaps);
            toast.success("Mandaps added successfully!");
          },
        });
      } else if (fileType === 'xlsx') {
        // Parse XLSX file
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryString = e.target.result;
          const wb = XLSX.read(binaryString, { type: 'binary' });
          const ws = wb.Sheets[wb.SheetNames[0]]; // Assuming first sheet
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
  
          const newMandaps = data.slice(1).map((row) => ({
            id: row[0], // Adjust based on your XLSX structure
            name: row[1], // Assuming name is in the second column
            location: row[2], // Assuming location is in the third column
            area: row[3], // Assuming area is in the fourth column
          }));
  
          updateMandapList(newMandaps);
          toast.success("Mandaps added successfully!");
        };
        reader.readAsBinaryString(file);
      } else {
        toast.error("Unsupported file type. Please upload a CSV or XLSX file.");
      }
    }
  };
  
  // Helper function to update the mandap list
  const updateMandapList = (newMandaps) => {
    if (editMode) {
      // Merge with the existing mandaps
      setMandapList((prevMandaps) => [...prevMandaps, ...newMandaps]);
    } else {
      // Replace with the new mandap list
      setMandapList(newMandaps);
    }
    // setFileUploaded(true);
    // handleCityChange(form.region);
  };
  useEffect(() => {
    if (editMode && propsState.id) {
      // Fetch data when entering edit mode
      axios
        .get(config.service + `/accounts/kae/${propsState.id}/`, {
          headers: tokenKey.headers,
        })
        .then((res) => {
          const data = res.data;
          console.log(data, "dataedit");
          setForm({
            name: data.name,
            email: data.email,
            phone_number: data?.phone_number,
            // region: data.region.toLowerCase() || "",
            // start_date: data.start_date,
            // end_date: data.end_date,
            status: data.status,
            // assignedMandaps: filteredMandaps,
            assigned_agent: data.assigned_agent,
          });
          setMandapList(data?.mandaps);
          console.log("data", data?.mandaps);
        })
        .catch((err) => {
          toast.error("Failed to fetch user data", toastAttr);
          console.error(err);
        });
    }
  }, [editMode, propsState?.id]);
  const saveForm = () => {
    let id = editMode ? propsState.id : null,
      method = editMode ? "put" : "post";
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      console.log("Errors:", newErrors);
      toast.error("Enter all required details to proceed", toastAttr);
    } else {
      setLoader(true);
      let url = editMode
          ? config.service + `/accounts/kae/${propsState.id}/`
          : config.service + "/accounts/kae/",
        data = JSON.parse(JSON.stringify(form));
      if (editMode) data.profile_id = data.id;
      console.log("data", filteredMandaps);
      // debugger
      data.assignedMandaps = mandapList;
      console.log("payload", {
        url: url,
        method: method,
        headers: tokenKey.headers,
        data: data,
      });

      axios({
        url: url,
        method: method,
        headers: tokenKey.headers,
        data: data,
      })
        .then((res) => {
          let data = res.data;
          console.log(data, "data");
          if (editMode)
            setForm({
              name: data.name,
              email: data.email,
              phone_number: data.phone_number,
              // start_date: data.start_date,
              // end_date: data.end_date,
              status: data.status,
              // region: data.region,
              assigned_agent: data.assigned_agent,
              assignedMandaps: mandapList,
            });
          else
            setForm({
              ...form,
            });

          console.log("data", form);
          if (editMode===false){
            renderListView();
          }
         
          if (editMode) toast.success("Saved successfully", toastAttr);
          else
            toast.success("Executive created successfully", {
              ...toastAttr,
              autoClose: 4000,
            });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.response, "Error");
          if (err.response.status === 401) window.location.href = "/";
          else {
            seterrData(err.response && err.response.data);
            // sethandleErr(true);
          }
          toast.error(
            "Saving Failed check Agent and email already allocated",
            toastAttr
          );
          setLoader(false);
        });
    }
  };
  console.log(filteredMandaps, "errData");
  const closeErrorHandling = () => {
    // sethandleErr(false);
    seterrData(null);
  };
  // console.log(form?.assigned_agent, "filterMandaps");
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody className="theme-light">
              <div className="card__title pageNav">
                <div className="bold-text">
                  <Breadcrumbs data={breadcrumbData} />
                </div>
              </div>
              <Row className="formBtnsDiv">
                <Col md={6}>
                  <Button variant="primary" onClick={saveForm}>
                    Save
                  </Button>
                  <Button variant="secondary" onClick={renderListView}>
                    Cancel
                  </Button>
                </Col>
              </Row>
              <Row className="formDiv">
                <Col md={12}>
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <div className="tabList">
                        {tabData.map((tab, index) =>
                          tab.show ? (
                            <a
                              key={index}
                              className={`nav-item nav-link ${
                                tab.active ? "active" : ""
                              }`}
                              id={tab.id}
                              name={index}
                              data-toggle="tab"
                              role="tab"
                              aria-controls="nav-details"
                              aria-selected={tab.active}
                            >
                              {tab.name}
                            </a>
                          ) : null
                        )}
                      </div>
                    </div>
                  </nav>
                  <div className="tabContent">
                    <Form className="form defaultForm">
                      <Loader loading={loading} overlay={overlay}>
                        {tab.details && (
                          <Row>
                            <Col md={12}>
                              <fieldset className="border p-2 fieldsetCls">
                                <legend className="w-auto">
                                  Executive Details
                                </legend>

                                <Row>
                                  <Col md={6}>
                                    {/* Left side fields */}
                                    {/* Name Field */}
                                    <Row>
                                      {editMode === false ? (
                                        <>
                                          <Col md={4} className="mb-3">
                                            <Form.Label>Agent</Form.Label>
                                          </Col>
                                          <Col md={7}>
                                            <select
                                              onChange={(e) => {
                                                const selectedAgentId =
                                                  e.target.value;
                                                console.log(
                                                  selectedAgentId,
                                                  "selectedAgentId"
                                                );
                                                handleAgentSelection(
                                                  selectedAgentId
                                                ); // Handle agent selection here
                                              }}
                                              value={form?.agent} // Holds the selected agent's ID
                                              id="agentSelect"
                                              className="form-control"
                                              style={{
                                                color: "black",
                                                width: "100%",
                                                fontSize: "14px",
                                              }}
                                              name="agent"
                                              required
                                            >
                                              <option value="">
                                                Select an Agent
                                              </option>{" "}
                                              {/* Default option */}
                                              {agentList.map((agent) => (
                                                <option
                                                  key={agent.id}
                                                  value={agent.id}
                                                >
                                                  {agent.name}
                                                </option>
                                              ))}
                                            </select>
                                            {errors.agent && (
                                              <div className="invalid-feedback">
                                                {errors.agent}
                                              </div>
                                            )}
                                          </Col>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <Col md={4}>
                                        <Form.Label>Name</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="text"
                                          name="name"
                                          value={form.name}
                                          onChange={(e) =>
                                            handleInput("name", e.target.value)
                                          }
                                          isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.name}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>

                                    {/* Email Field */}
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Email</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          value={form.email}
                                          onChange={(e) =>
                                            handleInput("email", e.target.value)
                                          }
                                          isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.email}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>

                                    {/* Mobile Field */}
                                    <Row>
                                      <Col md={4}>
                                        <Form.Label>Mobile</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="number"
                                          name="phone_number"
                                          value={form.phone_number}
                                          onChange={(e) =>
                                            handleInput(
                                              "phone_number",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.phone_number}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.phone_number}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row>

                                    {/* Region Field */}
                                    {/* <Row>
                                      <Col md={4}>
                                        <Form.Label>Region</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <select
                                          onChange={(e) => {
                                            const selectedCity = e.target.value;
                                            console.log(
                                              selectedCity,
                                              "selectedCity"
                                            );
                                            handleInput("region", selectedCity); // Call your existing function
                                            // handleCityChange(selectedCity); // Call your city change handler here
                                          }}
                                          value={form?.region}
                                          id="homeRegion"
                                          className="form-control"
                                          style={{
                                            color: "black",
                                            width: "100%",
                                            fontSize: "14px",
                                          }}
                                          name="region"
                                          required
                                        >
                                          {cityList.map((obj) => (
                                            <option
                                              key={obj.value}
                                              value={obj.value.toLowerCase()} // Ensure city values are in lowercase
                                            >
                                              {obj.label}
                                            </option>
                                          ))}
                                        </select>
                                        {errors.region && (
                                          <div className="invalid-feedback">
                                            {errors.region}
                                          </div>
                                        )}
                                      </Col>
                                    </Row> */}

                                    {/* Start Date Field */}
                                    {/* <Row>
                                      <Col md={4}>
                                        <Form.Label>Start Date</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="date"
                                          name="start_date"
                                          value={form.start_date}
                                          onChange={(e) =>
                                            handleInput(
                                              "start_date",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.start_date}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.start_date}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row> */}

                                    {/* End Date Field */}
                                    {/* <Row>
                                      <Col md={4}>
                                        <Form.Label>End Date</Form.Label>
                                      </Col>
                                      <Col md={7}>
                                        <Form.Control
                                          type="date"
                                          name="end_date"
                                          value={form.end_date}
                                          onChange={(e) =>
                                            handleInput(
                                              "end_date",
                                              e.target.value
                                            )
                                          }
                                          isInvalid={!!errors.end_date}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {errors.end_date}
                                        </Form.Control.Feedback>
                                      </Col>
                                    </Row> */}

                                    {/* Status Field */}
                                    <Row>
                                      <Col md={4}>
                                        <label>Status</label>
                                      </Col>
                                      <Col md={7}>
                                        <Switch
                                          checked={form.status === "active"}
                                          onChange={(e) =>
                                            handleInput(
                                              "status",
                                              e.target.checked
                                                ? "active"
                                                : "inactive"
                                            )
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        <span>
                                          {form.status === "active"
                                            ? "Active"
                                            : "Inactive"}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md={6}>
                                    <legend className="w-auto">
                                      Assign Mandaps
                                    </legend>
                                    {/* Role Field (Single Select Dropdown) */}

                                    {/* Multi-Select for Mandaps */}
                                    <Row>
                                      <Col md={12}>
                                        {/* <fieldset className=" p-2 fieldsetCls"> */}
                                        {/* <legend className="w-auto">
                                            Mandap Assignment
                                          </legend> */}
                                        <Row>
                                          <Col md={4}>
                                            <Form.Label>Upload CSV</Form.Label>
                                          </Col>
                                          <Col md={7}>
                                            <Form.Control
                                              type="file"
                                              onChange={handleFileChange}
                                            />
                                          </Col>
                                        </Row>
                                        {/* <Row className="mb-2">
                                          {fileUploaded && (
                                            <>
                                              <Col md={4}>
                                                <Form.Label>
                                                  Choose City Assign Mandap
                                                </Form.Label>
                                              </Col>
                                              <Col md={7}>
                                                <select
                                                  onChange={(e) =>
                                                    handleCityChange(
                                                      e.target.value
                                                    )
                                                  } // Handling city change
                                                  value={selectedCity} // Use selectedCity to reflect the selected city
                                                  className="form-control"
                                                  style={{
                                                    color: "black",
                                                    width: "100%",
                                                    fontSize: "14px",
                                                  }}
                                                  required
                                                  disabled={!fileUploaded} // Disable the city dropdown if file is not uploaded
                                                >
                                                  {cityList.map((obj) => (
                                                    <option
                                                      key={obj.value}
                                                      value={obj.value.toLowerCase()}
                                                    >
                                                      {obj.label}
                                                    </option>
                                                  ))}
                                                </select>
                                              </Col>

                                              {areaList.length > 0 && (
                                                <>
                                                  <Col md={4} className="mt-2">
                                                    <Form.Label>
                                                      Choose Area
                                                    </Form.Label>
                                                  </Col>
                                                  <Col md={7} className="mt-2">
                                                    <select
                                                      onChange={(e) =>
                                                        handleAreaChange(
                                                          e.target.value
                                                        )
                                                      } // Handling area change
                                                      value={selectedArea} // Use selectedArea to reflect the selected area
                                                      className="form-control"
                                                      style={{
                                                        color: "black",
                                                        width: "100%",
                                                        fontSize: "14px",
                                                      }}
                                                      disabled={!fileUploaded} // Disable the area dropdown if file is not uploaded
                                                    >
                                                      {areaList.map((area) => (
                                                        <option
                                                          key={area.value}
                                                          value={area.value}
                                                        >
                                                          {area.label}
                                                        </option>
                                                      ))}
                                                    </select>
                                                  </Col>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </Row> */}
                                        {/* Filtered Mandap List */}
                                        <Row>
                                          <Col md={12} className="">
                                            <h5>
                                              Available Mandaps in Selected
                                              City:
                                            </h5>
                                            <div
                                              style={{
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                              }}
                                            >
                                              <ul>
                                                {editMode === true
                                                  ? // In edit mode, show the current mandap details assigned to the KAE
                                                    mandapList?.map(
                                                      (mandap) => (
                                                        <li key={mandap.id}>
                                                          {mandap.mandap_name ||
                                                            mandap.name}{" "}
                                                          {/* Fallback to `name` if `mandap_name` is not present */}
                                                          <span className="font-weight-bold">
                                                            {"-"}{" "}
                                                            {mandap.area }-
                                                              {mandap.location ||
                                                              mandap.address_1}
                                                          </span>
                                                          {/* <span className="font-weight-bold">
                                                        {" "} {getAreaNameById(mandap.area_id)}
                                                      </span> */}
                                                        </li>
                                                      )
                                                    )
                                                  : // In creation mode, show the filtered mandaps
                                                    mandapList?.map(
                                                      (mandap) => (
                                                        console.log(
                                                          mandap,
                                                          "mandap"
                                                        ),
                                                        (
                                                          <li key={mandap.id}>
                                                            {mandap.name}{" "}
                                                            <span className="font-weight-bold">
                                                              {""} -{" "}
                                                              {mandap.area}{" "}
                                                            </span>
                                                            <span className="font-weight-bold">
                                                              {""} -{" "}
                                                              {mandap.location}{" "}
                                                              {/* Assuming this is the location property */}
                                                            </span>
                                                          </li>
                                                        )
                                                      )
                                                    )}
                                              </ul>
                                            </div>
                                          </Col>
                                        </Row>
                                        {/* </fieldset>  */}
                                      </Col>
                                    </Row>

                                    <Button
                                      onClick={() => {
                                        setOpenNested(true);
                                        setMandapList([]);
                                      }}
                                    >
                                      Remove Mandap & save
                                    </Button>
                                  </Col>
                                </Row>
                              </fieldset>
                            </Col>
                          </Row>
                        )}
                      </Loader>
                    </Form>
                  </div>
                </Col>
              </Row>
              {/* {handleErr && (
                <ErrorHandling
                  title="user"
                  closeErrorHandling={closeErrorHandling}
                  error={errData}
                />
              )} */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmationModel
        operation={"Remove"}
        handleConfirmation={() => {
          setOpenNested(false);
          saveForm();
        }}
        val={openNested}
        modelName={"Mandaps"}
        onclose={() => {
          setOpenNested(false);
        }}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default ExecutiveForm;
