import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Card,
  Row,
  Col,
  Container,
  Spinner,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import Breadcrumbs from "../../Helper/Breadcrumbs";
import { useHistory } from "react-router-dom";
import ConfirmationModel from "../../../shared/ConfirmationModel";
import config from "../../../config";
import { Menu, MenuItem } from "@mui/material";
import PaginationComponent from "./PaginationComponent";

const OfflineLeadsScreen = () => {
  const history = useHistory();
  const [leads, setLeads] = useState([]);
  const [currentLead, setCurrentLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);

  const [filters, setFilters] = useState({
    reportFromDate: "",
    reportToDate: "",
    eventType: "wedding",
    bookingType: "fullday",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const tokenConfig = {
    headers: { Authorization: `Token ${sessionStorage.getItem("tokenKey")}` },
  };

  const breadcrumbData = [{ title: "Offline Leads", href: "#" }];
  const fetchLeads = () => {
    setLoading(true);
    axios
      .get(`${config.service}/masters/offline_leads/`, {
        params: { page: pagination.page, page_size: pagination.pageSize },
        ...tokenConfig,
      })
      .then(({ data }) => {
        setLeads(data.results);
        setPagination((prev) => ({
          ...prev,
          totalPages: Math.ceil(data.count / prev.pageSize),
        }));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  useEffect(fetchLeads, [pagination.page]);
  // const handleDownloadReport = () => {
  //   setIsDownloading(true);

  //   axios
  //     .get(`${config.service}/masters/offline_leads/list`, tokenConfig)
  //     .then((response) => {
  //       const filteredLeads = response.data.filter((lead) => {
  //         const reportStartDate = filters.reportFromDate
  //           ? new Date(filters.reportFromDate)
  //           : null;
  //         const reportEndDate = filters.reportToDate
  //           ? new Date(filters.reportToDate)
  //           : null;
  //           const createdAt = lead.created_at;

  //           // Extract date and time
  //           const [datePart, timePart] = createdAt.split(" ");
  //           const [day, month, year] = datePart.split("/");
            
  //           // Combine date and time to create a valid Date object
  //           const formattedDate = new Date(`${year}-${month}-${day}T${timePart.slice(0, 8)}`);
            
  //           // Format the date to match the report date style
  //           const formattedString = formattedDate.toString(); // Example: "Tue Dec 31 2024 05:30:00 GMT+0530 (India Standard Time)"
            
  //           console.log(formattedString);
            
  //         console.log("reportStartDate:", reportStartDate);
  //         console.log("reportEndDate:", reportEndDate);
  //         console.log(lead?.created_at, "lead?.created_at");
  //         // Filter for any lead event that matches the date filter
  //         const matchesDateFilter = lead.lead_events.some((event) => {
  //           const eventStartDate = new Date(event.event_date_from);
  //           const eventEndDate = new Date(event.event_date_to);

  //           console.log("Checking event:", event);
  //           console.log("eventStartDate:", eventStartDate);
  //           console.log("eventEndDate:", eventEndDate);

  //           const dateMatches =
  //             (!reportStartDate || formattedString >= reportStartDate) &&
  //             (!reportEndDate || formattedString <= reportEndDate);
  //           console.log("Date matches:", dateMatches);
  //           return dateMatches;
  //         });

  //         console.log("matchesDateFilter:", matchesDateFilter);

  //         const matchesEventTypeFilter =
  //           !filters.eventType ||
  //           filters.eventType === "" ||
  //           lead.event_type?.toLowerCase() === filters.eventType.toLowerCase();

  //         console.log("matchesEventTypeFilter:", matchesEventTypeFilter);

  //         const matchesBookingTypeFilter =
  //           !filters.bookingType ||
  //           filters.bookingType === "" ||
  //           lead.booking_type?.toLowerCase() ===
  //             filters.bookingType.toLowerCase();

  //         console.log("matchesBookingTypeFilter:", matchesBookingTypeFilter);

  //         const result = matchesDateFilter;

  //         console.log("Lead matches all filters:", result);
  //         return result;
  //       });

  //       console.log("Filtered Leads:", filteredLeads);

  //       const leadsToExport =
  //         filters.reportFromDate || filters.reportToDate
  //           ? filteredLeads
  //           : response.data;

  //       console.log("Leads to export:", leadsToExport);

  //       const csvData = [
  //         "KAE Name,Mandapam Name,City,Customer Name,Email,Contact Number,Event Type,Event Subtype,Event Date,Booking Type & Time Slot",
  //         ...leadsToExport.map((lead) => {
  //           const eventTypes = lead.lead_events
  //             .map((event, index) => ` ${event.event_type || ""}`)
  //             .join("\n"); // Numbered list for Event Types
  //           const eventSubtypes = lead.lead_events
  //             .map(
  //               (event, index) => ` ${event.event_subtype || ""}`
  //             )
  //             .join("\n"); // Numbered list for Event Subtypes
  //           const eventDates = lead.lead_events
  //             .map(
  //               (event, index) =>
  //                 ` ${event.event_date_from || ""} - ${
  //                   event.event_date_to || ""
  //                 }`
  //             )
  //             .join("\n"); // Numbered list for Event Date range
  //           const bookingDetails = lead.lead_events
  //             .map(
  //               (event, index) =>
  //                 ` ${event.booking_type || ""} (${
  //                   event.start_time || ""
  //                 } - ${event.end_time || ""})`
  //             )
  //             .join("\n"); // Numbered list for Booking details

  //           return [
  //             lead.kae?.user_id?.username || "",
  //             lead.mandap?.mandap_name || "",
  //             lead.mandap?.city_name || "",
  //             lead.customer_name || "",
  //             lead.email || "",
  //             lead.contact_number || "",
  //             `"${eventTypes}"`, // Numbered list for Event Types
  //             `"${eventSubtypes}"`, // Numbered list for Event Subtypes
  //             `"${eventDates}"`, // Numbered list for Event Date range
  //             `"${bookingDetails}"`, // Numbered list for Booking details
  //           ].join(",");
  //         }),
  //       ].join("\n");

  //       const blob = new Blob([csvData], { type: "text/csv" });
  //       const url = URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = "offline_leads_report.csv";
  //       link.click();
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) =>
  //       console.error("Error fetching or generating report:", error)
  //     )
  //     .finally(() => setIsDownloading(false));
  // };
  const handleDownloadReport = () => {
    setIsDownloading(true);
  
    axios
      .get(`${config.service}/masters/offline_leads/list`, tokenConfig)
      .then((response) => {
        const filteredLeads = response.data.filter((lead) => {
          const reportStartDate = filters.reportFromDate
            ? new Date(filters.reportFromDate)
            : null;
          const reportEndDate = filters.reportToDate
            ? new Date(filters.reportToDate)
            : null;
  
          // Parse created_at as a Date object
          const createdAt = lead.created_at;

          // Extract date and time
          const [datePart, timePart] = createdAt.split(" ");
          const [day, month, year] = datePart.split("/");
          
          // Combine date and time to create a valid Date object
          const formattedDate = new Date(`${year}-${month}-${day}T${timePart.slice(0, 8)}`);
          
          // Format the date to match the report date style
          const formattedCreatedAt = new Date(formattedDate); // Example: "Tue Dec 31 2024 05:30:00 GMT+0530 (India Standard Time)"
          
          console.log("Report Created At:", formattedCreatedAt);

          console.log("Report Start Date:", reportStartDate);
          console.log("Report End Date:", reportEndDate);
          
  
          const matchesDateFilter =
            ( formattedCreatedAt >= reportStartDate) &&
            ( formattedCreatedAt <= reportEndDate);
  
          console.log("matchesDateFilter:", matchesDateFilter);
  
          // const matchesEventTypeFilter =
          //   !filters.eventType ||
          //   filters.eventType === "" ||
          //   lead.event_type?.toLowerCase() === filters.eventType.toLowerCase();
  
          // console.log("matchesEventTypeFilter:", matchesEventTypeFilter);
  
          // const matchesBookingTypeFilter =
          //   !filters.bookingType ||
          //   filters.bookingType === "" ||
          //   lead.booking_type?.toLowerCase() ===
          //     filters.bookingType.toLowerCase();
  
          // console.log("matchesBookingTypeFilter:", matchesBookingTypeFilter);
  
          const result = matchesDateFilter 
          
          // && matchesEventTypeFilter && matchesBookingTypeFilter;
  
          console.log("Lead matches all filters:", result);
          return result;
        });
  
        console.log("Filtered Leads:", filteredLeads);
  
        const leadsToExport =
          filters.reportFromDate || filters.reportToDate
            ? filteredLeads
            : response.data;
  
        console.log("Leads to export:", leadsToExport);
  
        const csvData = [
          "KAE Name,Mandapam Name,City,Customer Name,Email,Contact Number,Event Type,Event Subtype,Event Date, Booking Type & Time Slot, Created_at",
          ...leadsToExport.map((lead) => {
            const eventTypes = lead.lead_events
              .map((event, index) => ` ${event.event_type || ""}`)
              .join("\n"); // Numbered list for Event Types
            const eventSubtypes = lead.lead_events
              .map(
                (event, index) => ` ${event.event_subtype || ""}`
              )
              .join("\n"); // Numbered list for Event Subtypes
            const eventDates = lead.lead_events
              .map(
                (event, index) =>
                  ` ${event.event_date_from || ""} - ${
                    event.event_date_to || ""
                  }`
              )
              .join("\n"); // Numbered list for Event Date range
            const bookingDetails = lead.lead_events
              .map(
                (event, index) =>
                  `${event.booking_type || ""} (${
                    event.start_time || ""
                  } - ${event.end_time || ""})`
              )
              .join("\n"); // Numbered list for Booking details

            return [
              lead.kae?.user_id?.username || "",
              lead.mandap?.mandap_name || "",
              lead.mandap?.city_name || "",
              lead.customer_name || "",
              lead.email || "",
              lead.contact_number || "",
              `"${eventTypes}"`, // Numbered list for Event Types
              `"${eventSubtypes}"`, // Numbered list for Event Subtypes
              `"${eventDates}"`, // Numbered list for Event Date range
              `"${bookingDetails}"`, // Numbered list for Booking details
              lead.created_at || "",
            ].join(",");
          }),
        ].join("\n");

        console.log("CSV Data:", csvData);
  
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "offline_leads_report.csv";
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) =>
        console.error("Error fetching or generating report:", error)
      )
      .finally(() => setIsDownloading(false));
  };
  
  const handleMenuOpen = (event, lead) => {
    setAnchorEl(event.currentTarget);
    setCurrentLead(lead);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    history.push({
      pathname: "/offlineleadsform",
      state: { id: currentLead.id, editMode: true },
    });
  };

  const handleDeleteConfirmation = (leadId, lead) => {
    handleMenuClose();
    setSelectedLeadId(leadId);
    setSelectedLead(lead);
    setOpenConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      console.log(selectedLead, "selectedLead");

      const leadEvents = selectedLead?.lead_events.map((event) => ({
        event_type: event.event_type,
        event_subtype: event.event_subtype,
        event_date_from: event.event_date_from,
        event_date_to: event.event_date_to,
        start_time: event.start_time,
        end_time: event.end_time,
        booking_type: event.booking_type,
      }));

      let allocatedDates = [];

      for (const event of leadEvents) {
        if (event.booking_type === "full_day") {
          allocatedDates.push(
            {
              timing: "half_day_morning",
              is_blocked: false,
              blocked_by: "agent",
              date: event.event_date_from,
            },
            {
              timing: "half_day_evening",
              is_blocked: false,
              blocked_by: "agent",
              date: event.event_date_from,
            }
          );
        } else {
          allocatedDates.push({
            timing: event.booking_type,
            is_blocked: false,
            blocked_by: "agent",
            date: event.event_date_from,
          });
        }
      }

      const mandapRemovalPayload = {
        allocated_dates: allocatedDates,
        booking_id: selectedLead.booking_id || null,
      };

      console.log("Mandap Removal Payload:", mandapRemovalPayload);

      const mandapId = selectedLead.mandap.id;

      await axios.post(
        `${config.service}/vendor/mandap-allocation/${mandapId}/`,
        mandapRemovalPayload,
        { ...tokenConfig }
      );

      await axios.delete(
        `${config.service}/masters/offline_leads/remove/${selectedLeadId}/`,
        tokenConfig
      );

      setLeads((prevLeads) =>
        prevLeads.filter((lead) => lead.id !== selectedLeadId)
      );

      setOpenConfirmation(false);

      fetchLeads();

      console.log(
        "Lead and associated mandap allocations removed successfully."
      );
    } catch (error) {
      console.error(
        "Error during lead deletion or mandap allocation removal:",
        error
      );
    } finally {
      setOpenConfirmation(false);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "eventType" && value === "all") {
      setFilters((prevFilters) => ({ ...prevFilters, eventType: "" }));
    } else if (field === "bookingType" && value === "all") {
      setFilters((prevFilters) => ({ ...prevFilters, bookingType: "" }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
    }
  };

  return (
    <div>
      <Breadcrumbs data={breadcrumbData} />
      <Container>
        <Card>
          <Card.Body className="theme-light">
            <Row className="formBtnsDiv mb-3">
              <Col md={6}>
                <Button
                  variant="primary"
                  onClick={() => history.push("/offlineleadsform")}
                >
                  Create New Lead
                </Button>
              </Col>
              <Col md={6} className="d-flex justify-content-end ">
                <DropdownButton
                  variant="outline-primary"
                  title="Generate Report"
                >
                  <div className="p-3">
                    <Form>
                      <Form.Group controlId="fromDate" className="mb-3">
                        <Form.Label className="fw-bold">From Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={filters.reportFromDate}
                          onChange={(e) =>
                            handleInputChange("reportFromDate", e.target.value)
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="toDate" className="mb-3">
                        <Form.Label className="fw-bold">To Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={filters.reportToDate}
                          onChange={(e) =>
                            handleInputChange("reportToDate", e.target.value)
                          }
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={handleDownloadReport}
                        disabled={isDownloading}
                      >
                        {isDownloading ? (
                          <>
                            <Spinner animation="border" size="sm" />{" "}
                            Downloading...
                          </>
                        ) : (
                          "Download Report"
                        )}
                      </Button>
                    </Form>
                  </div>
                </DropdownButton>
              </Col>
            </Row>

            {loading ? (
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
                <p>Loading leads...</p>
              </div>
            ) : (
              <>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Agent Name</th>
                      <th>Mandapam Name</th>
                      <th>City</th>
                      <th>Customer Name</th>
                      <th>Contact Number</th>
                      <th>Event Type</th>
                      <th>Event Subtype</th>
                      <th>Event Date (From - To)</th>
                      <th>Booking Type & Time Slot</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leads?.length === 0 ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No leads found.
                        </td>
                      </tr>
                    ) : (
                      leads.map((lead) => (
                        <tr key={lead.id}>
                          <td>{lead.kae?.user_id?.username || "N/A"}</td>

                          <td>{lead.mandap?.mandap_name || "N/A"}</td>

                          <td>{lead.mandap?.city_name || "N/A"}</td>

                          <td>{lead.customer_name || "N/A"}</td>

                          <td>{lead.contact_number || "N/A"}</td>

                          <td style={{ whiteSpace: "nowrap" }}>
                            {lead.lead_events?.map((event, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "10px 0px",
                                  borderBottom:
                                    index === lead.lead_events.length - 1
                                      ? "none"
                                      : "1px solid #ccc",
                                }}
                              >
                                <div>{`${event.event_type || "N/A"}`}</div>
                              </div>
                            ))}
                          </td>

                          <td style={{ whiteSpace: "nowrap" }}>
                            {lead.lead_events?.map((event, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "10px 0px",
                                  borderBottom:
                                    index === lead.lead_events.length - 1
                                      ? "none"
                                      : "1px solid #ccc",
                                }}
                              >
                                <div>{`${event.event_subtype || "N/A"}`}</div>
                              </div>
                            ))}
                          </td>

                          <td style={{ whiteSpace: "nowrap" }}>
                            {lead.lead_events?.map((event, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "10px 0px",
                                  borderBottom:
                                    index === lead.lead_events.length - 1
                                      ? "none"
                                      : "1px solid #ccc",
                                }}
                              >
                                <div>{`${event.event_date_from} to ${event.event_date_to}`}</div>
                              </div>
                            ))}
                          </td>

                          <td style={{ whiteSpace: "nowrap" }}>
                            {lead.lead_events?.map((event, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "10px 0px",
                                  borderBottom:
                                    index === lead.lead_events.length - 1
                                      ? "none"
                                      : "1px solid #ccc",
                                }}
                              >
                                <strong>{`${event.booking_type} (${
                                  event.start_time || "N/A"
                                } - ${event.end_time || "N/A"})`}</strong>
                              </div>
                            ))}
                          </td>

                          <td>
                            <Button
                              variant="link"
                              onClick={(e) => handleMenuOpen(e, lead)}
                            >
                              <FaEllipsisV />
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                <PaginationComponent
                  currentPage={pagination.page}
                  totalPages={pagination.totalPages}
                  onPageChange={(page) =>
                    setPagination((prev) => ({ ...prev, page }))
                  }
                />
              </>
            )}

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem
                onClick={() =>
                  handleDeleteConfirmation(currentLead.id, currentLead)
                }
              >
                Delete
              </MenuItem>
            </Menu>

            <ConfirmationModel
              operation="delete"
              handleConfirmation={handleDelete}
              val={openConfirmation}
              modelName="Lead"
              onclose={() => setOpenConfirmation(false)}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default OfflineLeadsScreen;
