import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import {
  FaChartArea,
  FaUsers,
  FaHome,
  FaAddressBook,
  FaUserTie,
  FaObjectGroup,
  FaCog,
  FaMoneyCheck,
  FaFileInvoice,
  FaMoneyBillWave,
  FaCoins,
  FaHandshake,
} from "react-icons/fa";
import { SiGooglebigquery } from "react-icons/si";
import { ImUsers } from "react-icons/im";
import { RiQuestionAnswerLine } from "react-icons/ri";
const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
}) => {
  let role = sessionStorage.getItem("permission")
    ? JSON.parse(sessionStorage.getItem("permission"))[0].user_role
    : "agent";
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Dashboard"
          icon={<FaChartArea />}
          route="/dashboard"
          onClick={onClick}
        />
        <SidebarLink
          title="Notifications"
          icon={<RiQuestionAnswerLine />}
          route="/notifications"
          onClick={onClick}
        />
        <SidebarLink
          title="Vendor"
          icon={<FaUsers />}
          route="/vendor"
          onClick={onClick}
        />
        <SidebarLink
          title="Report"
          icon={<FaAddressBook />}
          route="/report"
          onClick={onClick}
        />
        <SidebarLink
          title="Venue Inquiry Point"
          icon={<SiGooglebigquery />}
          route="/inquirypoint"
          onClick={onClick}
        />{" "}
        <SidebarLink
          title="Sign Up Leads"
          icon={<SiGooglebigquery />}
          route="/signuppoint"
          onClick={onClick}
        />
        <SidebarLink
          title="Enquiry/booking Report"
          icon={<FaAddressBook />}
          route="/enqbook"
          onClick={onClick}
        />
        <SidebarLink
          title="Bookings"
          icon={<FaAddressBook />}
          route="/booking"
          onClick={onClick}
        />
        <SidebarLink
          title="Mandap"
          icon={<FaHome />}
          route="/mandapList"
          onClick={onClick}
        />
        <SidebarLink
          title="User"
          icon={<FaUserTie />}
          route="/user"
          onClick={onClick}
        />
        <SidebarLink
          title="Masters"
          icon={<FaObjectGroup />}
          route="/masters"
          onClick={onClick}
        />
        <SidebarLink
          title="Customers"
          icon={<ImUsers />}
          route="/customers"
          onClick={onClick}
        />
        <SidebarLink
          title="Enquiry"
          icon={<RiQuestionAnswerLine />}
          route="/enquiry"
          onClick={onClick}
        />
        <SidebarLink
          title="Payments"
          icon={<FaMoneyCheck />}
          route="/payment"
          onClick={onClick}
        />
        <SidebarLink
          title="Payments Status"
          icon={<FaMoneyCheck />}
          route="/paymentstatus"
          onClick={onClick}
        />
        <SidebarLink
          title="Vouchers"
          icon={<FaFileInvoice />}
          route="/voucher"
          onClick={onClick}
        />
        <SidebarLink
          title="Cashback"
          icon={<FaMoneyBillWave />}
          route="/cashback"
          onClick={onClick}
        />
        <SidebarLink
          title="Bonus"
          icon={<FaCoins />}
          route="/claimBonus"
          onClick={onClick}
        />
        <SidebarLink
          title="Manage"
          icon={<FaCog />}
          route="/settings"
          onClick={onClick}
        />
        <SidebarLink
          title="Executives"
          icon={<FaUsers />}
          route="/executives"
          onClick={onClick}
        />
        <SidebarLink
          title="Offline Leads"
          icon={<FaHandshake />}
          route="/offlineleads"
          onClick={onClick}
        />
      </ul>
      {/* <ul className="sidebar__block">
      <SidebarCategory title="Example Pages" icon="diamond" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Page one" route="/pages/one" onClick={onClick} />
        <SidebarLink title="Page two" route="/pages/two" onClick={onClick} />
        <SidebarCategory title="Example Subpages" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Subpage one" route="/" onClick={onClick} />
          <SidebarLink title="Subpage two" route="/" onClick={onClick} />
        </SidebarCategory>
      </SidebarCategory>
    </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
